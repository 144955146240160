// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Theme
import { palette } from 'theme/theme';

// Constants
import { BLACK_MAIN } from 'constants/color';

// Elements
import { Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const DraftPick = ({ data, align = 'left', horizontal = false }) => {
  const fontSize = horizontal ? 12 : 10;
  const roleSize = horizontal ? 24 : 16;
  const imageSize = horizontal ? 40 : 28;
  const roleStyle = {
    position: !horizontal && 'absolute',
    top: !horizontal && '50%',
    left: !horizontal && align === 'right' ? 8 : 'auto',
    right: !horizontal && align === 'left' ? 8 : 'auto',
    transform: !horizontal && 'translateY(-50%)',
    color: BLACK_MAIN,
    backgroundColor: palette[data.role.toLowerCase()].main,
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        width={horizontal ? '100%' : roleSize}
        height={roleSize}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={roleStyle}
      >
        <Typography component="span" fontSize={fontSize} fontWeight="bold">
          {data.role[0]}
        </Typography>
      </Box>
      <ImageItem
        src={data.image_url}
        alt={data.name}
        width={imageSize}
        height={imageSize}
        noborder
      />
    </Box>
  );
};

DraftPick.propTypes = {
  data: PropTypes.object.isRequired,
  align: PropTypes.string,
  horizontal: PropTypes.bool,
};

export default DraftPick;
