export const MATCH_TYPES = {
  ALL: 'ALL',
  SCRIM: 'SCRIM',
  COMPETITIVE: 'COMPETITIVE',
  SOLOQ: 'SOLOQ',
  CAREER: 'CAREER',
};

export const MATCH_TYPES_LIST_BASIC = [
  {
    id: 'scrim',
    label: MATCH_TYPES.SCRIM,
    value: MATCH_TYPES.SCRIM,
  },
  {
    id: 'competitive',
    label: MATCH_TYPES.COMPETITIVE,
    value: MATCH_TYPES.COMPETITIVE,
  },
];

export const MATCH_TYPES_LIST_ALL = [
  {
    id: 'all',
    label: MATCH_TYPES.ALL,
    value: MATCH_TYPES.ALL,
  },
  ...MATCH_TYPES_LIST_BASIC,
  {
    id: 'soloq',
    label: MATCH_TYPES.SOLOQ,
    value: MATCH_TYPES.SOLOQ,
  },
  {
    id: 'career',
    label: MATCH_TYPES.CAREER,
    value: MATCH_TYPES.CAREER,
  },
];

export const MATCH_TYPES_S_LIST = [
  {
    id: 'all',
    label: MATCH_TYPES.ALL,
    value: MATCH_TYPES.ALL,
  },
  ...MATCH_TYPES_LIST_BASIC,
  {
    id: 'soloq',
    label: MATCH_TYPES.SOLOQ,
    value: MATCH_TYPES.SOLOQ,
  },
];

export const MATCH_TYPES_LIST = [
  {
    id: 'all',
    label: MATCH_TYPES.ALL,
    value: MATCH_TYPES.ALL,
  },
  ...MATCH_TYPES_LIST_BASIC,
];
