// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Typography, useMediaQuery } from '@mui/material';
import RoleChip from 'components/atoms/RoleChip/RoleChip';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';

// Component
const DraftPatternRole = ({ roles, count, winRate = null, index = 0 }) => {
  const isDesktopViewport = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const renderRoleChips = () =>
    roles.map((el, elIndex) => (
      <RoleChip key={el.trim()} text={el.trim()} role={el.trim()} ml={elIndex > 0 ? 0.5 : 0} />
    ));

  return (
    <Box display="flex" alignItems="center" mt={index > 0 ? 1 : 0}>
      {renderRoleChips()}
      {roles.length < 2 ? <Box sx={{ minWidth: 49 }} /> : null}
      <Typography variant="body2" component="p" sx={{ mx: 1, minWidth: 24 }}>
        {count}
      </Typography>
      {winRate != null && isDesktopViewport ? (
        <GamePercentageData value={winRate} threshold={50} fixNumbers />
      ) : null}
    </Box>
  );
};

DraftPatternRole.propTypes = {
  roles: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  winRate: PropTypes.number,
  index: PropTypes.number,
};

export default DraftPatternRole;
