// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { useMediaQuery } from '@mui/material';

// Component
const ImageAxis = ({ x = 0, y = 0, payload = {}, inverse = false }) => {
  let imageSize = 28;

  if (useMediaQuery((theme) => theme.breakpoints.up('sm'))) {
    imageSize = 40;
  }

  if (useMediaQuery((theme) => theme.breakpoints.up('md'))) {
    imageSize = 28;
  }

  if (useMediaQuery((theme) => theme.breakpoints.up('xl'))) {
    imageSize = 48;
  }

  const imageX = inverse ? x : x - imageSize;
  const imageY = y - imageSize / 2;

  return (
    <g transform={`translate(${imageX},${imageY})`}>
      <image href={payload?.value} width={imageSize} height={imageSize} />
    </g>
  );
};

ImageAxis.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  inverse: PropTypes.bool,
};

export default ImageAxis;
