// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Card, CardContent } from '@mui/material';
import CardTitle from 'components/atoms/CardTitle/CardTitle';
import CardValue from 'components/atoms/CardValue/CardValue';
import WinRateText from 'components/molecules/WinRateText/WinRateText';

// Component
const ChampionData = ({ data = null }) => {
  if (!data || Object.keys(data).length === 0) {
    return null;
  }

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(2, 1fr)',
        sm: 'repeat(3, 1fr)',
        md: 'repeat(4, 1fr)',
        lg: 'repeat(6, 1fr)',
        xl: 'repeat(8, 1fr)',
      }}
      gap={1}
    >
      <Card>
        <CardContent>
          <CardTitle text="Win rate" />
          <WinRateText data={data?.win_rate} />
          <CardTitle text="Games" mt={2} />
          <CardValue value={data?.games} center />
        </CardContent>
      </Card>
    </Box>
  );
};

ChampionData.propTypes = {
  data: PropTypes.object,
};

export default ChampionData;
