// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Routes
import { preparationAddURL, preparationBaseURL } from 'routes/app';

// Services
import { deletePreparation, fetchPreparations } from 'services/preparation';

// Constants
import { DRAFT_PREPARATION_LIST_COLS } from 'constants/tableHead';

// Assets
import AddIcon from '@mui/icons-material/Add';

// Elements
import { Box } from '@mui/material';
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import AdminList from 'components/molecules/AdminList/AdminList';

const PreparationView = () => {
  const {
    setTitleSEO,
    filters: {
      preparation: { user, user_data, matchType, side, teams, teams_data, rangeDate },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Preparations';

  const fetchData = (userData, matchTypeData, sideData, teamsData, startDateData, endDateData) => {
    setIsLoading(true);

    fetchPreparations(
      userData,
      matchTypeData,
      sideData,
      teamsData,
      startDateData,
      endDateData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        preparation: {
          ...prevValue.preparation,
          user_data: res.users,
          teams_data: res.teams,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(user, matchType, side, teams, rangeDate[0], rangeDate[1]);
    }
  }, [user, matchType, side, teams, rangeDate]);

  const buttons = [
    {
      id: 1,
      btnText: 'Add preparation',
      btnIcon: <AddIcon fontSize="small" />,
      btnLink: preparationAddURL,
    },
  ];

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <PageTitle title={title} disableGutterBottom />
      </Box>
      <DataFilters
        userFilter={user_data}
        matchTypeFilter
        rangeDateFilter
        sideFilter
        teamsFilter={teams_data}
        type="preparation"
      />
      <AdminList
        buttons={buttons}
        tableCols={DRAFT_PREPARATION_LIST_COLS}
        tableData={data}
        baseURL={preparationBaseURL}
        deleteFn={deletePreparation}
        refreshFn={() => fetchData(user, matchType, side, teams, rangeDate[0], rangeDate[1])}
      />
    </>
  );
};

export default PreparationView;
