// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Utils
import { secondsToHms } from 'utils/time';

// Constants
import { GREEN_MAIN, RED_MAIN, WHITE_MAIN } from 'constants/color';

// Elements
import { Typography } from '@mui/material';

// Component
const GameDurationData = ({ value = 0, diff = false, threshold = null, invert = false }) => {
  const valueData = diff ? secondsToHms(Math.abs(value)) : secondsToHms(value);
  let color = WHITE_MAIN;

  if (value !== null && (diff || threshold)) {
    if (diff) {
      if (value > 0) {
        color = GREEN_MAIN;
      } else if (value < 0) {
        color = RED_MAIN;
      }
    } else if (threshold) {
      if (value < threshold) {
        if (invert) {
          color = RED_MAIN;
        } else {
          color = GREEN_MAIN;
        }
      } else if (value > threshold) {
        if (invert) {
          color = GREEN_MAIN;
        } else {
          color = RED_MAIN;
        }
      }
    }
  }

  return (
    <Typography variant="body2" component="p" color={color}>
      {valueData}
    </Typography>
  );
};

GameDurationData.propTypes = {
  value: PropTypes.number,
  diff: PropTypes.bool,
  threshold: PropTypes.number,
  invert: PropTypes.bool,
};

export default GameDurationData;
