// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Utils
import { generateUniqueKey } from 'utils/key';

// Assets
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// Elements
import { Grid2 as Grid, Box, Button, IconButton, Typography } from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';

// Component
const VODsSoloQManageData = ({ value, handleChange, setFieldValue }) => {
  const addNewVodsS = () => {
    setFieldValue('data', [
      ...value,
      {
        key: generateUniqueKey(),
        opgg: '',
        duration: '',
        notes: '',
      },
    ]);
  };

  const removeVodsS = (index) => {
    const updatedVodsS = [...value];
    updatedVodsS.splice(index, 1);

    setFieldValue('data', updatedVodsS);
  };

  return (
    <Box>
      <Typography variant="subtitle1">VODs soloQ</Typography>
      <Button variant="text" size="small" startIcon={<AddIcon />} onClick={addNewVodsS}>
        Add vod
      </Button>
      {value.map(({ key, opgg, duration, notes }, vodsSIndex) => (
        <Box key={key} mt={vodsSIndex > 0 ? 3 : 0}>
          <Grid container>
            <Grid size={{ xs: 12, md: 5 }}>
              <TextField
                name={`data[${vodsSIndex}].opgg`}
                label="OPGG Link"
                value={opgg || ''}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                name={`data[${vodsSIndex}].duration`}
                label="Duration"
                value={duration || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid size={{ xs: 12, md: 5 }}>
              <TextField
                name={`data[${vodsSIndex}].notes`}
                label="Notes"
                value={notes || ''}
                onChange={handleChange}
                rows={5}
                multiline
                fullWidth
              />
            </Grid>
            <Grid size={{ xs: 12, md: 2 }}>
              <Box mt={2}>
                <IconButton aria-label="delete" onClick={() => removeVodsS(vodsSIndex)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

VODsSoloQManageData.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default VODsSoloQManageData;
