// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Utils
import { calculateAge } from 'utils/date';

// Elements
import { Box, Card, CardContent } from '@mui/material';
import CardText from 'components/atoms/CardText/CardText';
import ImageItem from 'components/atoms/ImageItem/ImageItem';
import CardValue from 'components/atoms/CardValue/CardValue';
import RoleChip from 'components/atoms/RoleChip/RoleChip';
import TeamChip from 'components/atoms/TeamChip/TeamChip';

// Component
const PlayerCard = ({ object, title }) => {
  const image = object.image_url;
  const playerImageSize = 160;
  const teamImageSize = 32;
  const currentTeamContract = object.teams
    ? object.teams.find((team) => team.end_date === null)
    : null;
  const currentTeam = currentTeamContract ? currentTeamContract.team : null;
  const playerRole = currentTeamContract ? currentTeamContract.role : object.role;
  const playerNationalities = object.nationalities;
  const birthday = object.birthday ? object.birthday : null;
  const age = birthday ? calculateAge(birthday) : null;

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="center" mb={0.5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              maxWidth={playerImageSize}
              maxHeight={playerImageSize}
              mb={0.5}
            >
              <ImageItem
                src={image}
                alt={title}
                maxWidth={playerImageSize}
                maxHeight={playerImageSize}
                autoWidth
                autoHeight
              />
            </Box>
            <Box display="flex" alignItems="center">
              <CardText text="Player:" />
              {playerNationalities ? (
                <Box display="flex" alignItems="center" ml={1}>
                  {playerNationalities.map(({ nationality: itemNationality }, nationalityIndex) => (
                    <Box key={itemNationality.id} ml={nationalityIndex ? 1 / 2 : 0}>
                      <ImageItem
                        src={itemNationality.image_url}
                        alt={itemNationality.name}
                        height={18}
                        autoWidth
                      />
                    </Box>
                  ))}
                </Box>
              ) : null}
              <CardValue value={title} ml={1 / 2} />
              {age ? <CardValue value={`(${age} yo)`} ml={1 / 2} /> : null}
            </Box>
          </Box>
          {currentTeam ? (
            <Box display="flex" alignItems="center">
              <ImageItem
                src={currentTeam.image_url}
                alt={currentTeam.name}
                width={teamImageSize}
                height={teamImageSize}
              />
              <TeamChip text={currentTeam.name} ml={0.5} link={`/team/${currentTeam.slug}`} />
              <RoleChip text={playerRole} role={playerRole} ml={1} />
            </Box>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
};

PlayerCard.propTypes = {
  object: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default PlayerCard;
