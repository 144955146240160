// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Typography, Card, CardContent } from '@mui/material';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';
import CardTitle from 'components/atoms/CardTitle/CardTitle';
import CardText from 'components/atoms/CardText/CardText';
import CardValue from 'components/atoms/CardValue/CardValue';

// Component
const CardWinRate = ({ title, data, count = null }) => (
  <Card>
    <CardContent>
      <CardTitle text={title} />
      {count ? (
        <Typography variant="body2" component="p" textAlign="center">
          {count}
        </Typography>
      ) : null}
      {data.map(({ title: cardTitle, count: cardCount, winRate, mt }) => (
        <Box
          key={cardTitle}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={mt}
        >
          <CardText text={cardTitle} />
          <Box display="flex" alignItems="center">
            <CardValue value={cardCount} mr={3} />
            <GamePercentageData value={winRate} fixNumbers minWidth={65} />
          </Box>
        </Box>
      ))}
    </CardContent>
  </Card>
);

CardWinRate.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  count: PropTypes.number,
};

export default CardWinRate;
