// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Cookies
import { useCookies } from 'react-cookie';

// Notistack
import { useSnackbar } from 'notistack';

// Routes
import { teamsSingleBasicURL, playersSingleBasicURL } from 'routes/app';

// Services
import { checkMe, updateUserCurrentTeam } from 'services/users';
import { fetchSingleTeam } from 'services/teams';

// Constants
import { COOKIE_NAME } from 'constants/cookie';
import { ROLES_LIST } from 'constants/role';
import { SUCCESS_SNACKBAR, ERROR_SNACKBAR } from 'constants/snackbar';

// Elements
import { Box, Grid2 as Grid, Button } from '@mui/material';
import Select from 'components/atoms/Select/Select';
import LinkCard from 'components/molecules/LinkCard/LinkCard';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const HomeView = () => {
  const { setTitleSEO, user, setUser, currentTeamId, setCurrentTeamId, setIsLoading } =
    useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const [cookie] = useCookies([COOKIE_NAME]);
  const [options, setOptions] = useState([]);
  const [team, setTeam] = useState({});
  const [teamId, setTeamId] = useState('');
  const [players, setPlayers] = useState([]);
  const renderAfterCalled = useRef(false);

  const getTeamRoster = (value) =>
    fetchSingleTeam(value).then((resTeam) => {
      if (resTeam) {
        const teamRoster = resTeam?.players;

        if (teamRoster) {
          setPlayers(
            teamRoster
              .filter((a) => a.is_sub !== true)
              .sort((a, b) => ROLES_LIST.indexOf(a.role) - ROLES_LIST.indexOf(b.role)),
          );
        }
      }
    });

  const chooseData = (value) =>
    updateUserCurrentTeam(value || 0).then(({ data, error, message }) => {
      let variantSnackbar = SUCCESS_SNACKBAR;

      if (data) {
        setCurrentTeamId(data);

        if (!user?.players_home || user?.players_home.length === 0) {
          getTeamRoster(value);
        } else {
          setPlayers(user?.players_home);
        }
      }

      checkMe(cookie.token).then(({ data: respData }) => {
        if (respData) {
          setUser(respData);
        }
      });

      if (error) {
        variantSnackbar = ERROR_SNACKBAR;
      }

      enqueueSnackbar(message, {
        variant: variantSnackbar,
      });
    });

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      const newData = [];

      user?.teams_access.map(({ team: { id, name } }) =>
        newData.push({
          id,
          value: id,
          name,
        }),
      );

      newData.sort((a, b) => a.name.localeCompare(b.name));

      setOptions(newData);
    }

    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      const selectedAccess = user?.teams_access.find(({ team: { id } }) => id === teamId);

      if (selectedAccess) {
        setTeam(selectedAccess?.team);
      } else {
        setTeam({});
        setPlayers([]);
      }
    }
  }, [teamId]);

  useEffect(() => {
    if (currentTeamId) {
      setTeamId(currentTeamId);

      if (!user?.players_home || user?.players_home.length === 0) {
        getTeamRoster(currentTeamId);
      } else {
        setPlayers(user?.players_home);
      }
    }
  }, [currentTeamId]);

  useEffect(() => {
    if (user?.players_home && user?.players_home.length !== 0) {
      setPlayers(user?.players_home);
    }
  }, [user]);

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO('Home');
    }

    renderAfterCalled.current = true;
  }, []);

  return (
    <>
      {Object.keys(user).length > 0 && <PageTitle title={`Login as ${user.username}`} />}
      {options.length > 0 && (
        <>
          <Box display="flex" alignItems="center" mt={2} mb={1}>
            <Select
              disabled={options.length === 0}
              label="Current team"
              options={options}
              value={teamId}
              onChange={(e) => setTeamId(e.target.value)}
              minWidth={225}
            />
          </Box>
          <Button variant="contained" onClick={() => chooseData(teamId)}>
            Choose
          </Button>
        </>
      )}
      {team && Object.keys(team).length > 0 && (
        <Box mt={2}>
          <Grid container>
            <Grid size={{ xs: 12, sm: 4, lg: 2, xl: 1 }}>
              <LinkCard text="My team" link={`${teamsSingleBasicURL}/${team.slug}`} item={team} />
            </Grid>
          </Grid>
        </Box>
      )}
      {players && players.length > 0 && (
        <Box mt={1}>
          <Grid container>
            {players.map(({ player_id, player: { name, slug, image_url } }) => (
              <Grid key={player_id} size={{ xs: 6, sm: 4, lg: 2, xl: 1 }}>
                <LinkCard
                  text={name}
                  link={`${playersSingleBasicURL}/${slug}`}
                  item={{ image_url, name }}
                  height={80}
                  autoWidth
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default HomeView;
