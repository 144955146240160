// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Router
import { useNavigate } from 'react-router-dom';

// Theme
import { palette } from 'theme/theme';

// Constants
import { BLACK_MAIN } from 'constants/color';

// Elements
import { Chip } from '@mui/material';

// Component
const RoleChip = ({ text, role, ml = 0, link = null }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(link);
  };

  return (
    <Chip
      label={text}
      size="small"
      onClick={link ? handleClick : null}
      sx={{
        minWidth: 45,
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 700,
        color: BLACK_MAIN,
        backgroundColor: role ? palette[role.toLowerCase()].main : null,
        ml,
      }}
    />
  );
};

RoleChip.propTypes = {
  text: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  ml: PropTypes.number,
  link: PropTypes.string,
};

export default RoleChip;
