// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchChampionsQueues = () =>
  axiosLoadingInstance
    .get('champions-queues/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleChampionsQueue = (id) =>
  axiosLoadingInstance
    .get(`champions-queues/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addChampionsQueue = (data) =>
  axiosInstance
    .post('champions-queues/add/', data)
    .then((res) => ({ data: res.data, message: 'Champions Queue has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateChampionsQueue = (id, data) =>
  axiosInstance
    .put(`champions-queues/${id}/`, data)
    .then((res) => ({ data: res.data, message: 'Champions Queue has been updated!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteChampionsQueue = (id) =>
  axiosInstance
    .delete(`champions-queues/${id}/`)
    .then((res) => ({ data: res.data, message: 'Champions Queue has been deleted!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
