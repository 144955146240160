// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const ImageListData = ({ cell }) => {
  const { getValue } = cell;

  if (!getValue() || getValue().length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1 / 2,
      }}
    >
      {getValue().map(({ id, name, image_url, count }) => (
        <Box
          key={id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1 / 2,
          }}
        >
          <ImageItem src={image_url} alt={name} />
          {count ? (
            <Typography variant="body2" component="span">
              {count}
            </Typography>
          ) : null}
        </Box>
      ))}
    </Box>
  );
};

ImageListData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default ImageListData;
