// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Elements
import { Link as LinkMUI } from '@mui/material';

// Component
const Link = ({ text, href, ml = 0 }) => (
  <LinkMUI
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    variant="body2"
    component="a"
    underline="hover"
    sx={{ ml }}
  >
    {text}
  </LinkMUI>
);

Link.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  ml: PropTypes.number,
};

export default Link;
