// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Constants
import { GREEN_MAIN, BLACK_MAIN, GRAY_A50, WHITE_A10, GREEN_A10 } from 'constants/color';

// Elements
import { Box, Typography, Grid2 as Grid } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';
import DraftPick from 'components/molecules/DraftPick/DraftPick';
import VODData from 'components/atoms/VODData/VODData';
import ReplayData from 'components/atoms/ReplayData/ReplayData';

// Component
const Draft = ({ data }) => {
  const { id, provider_id, blue, red, is_blue_win, vod, replay } = data;
  const teamImageSize = 26;
  const championImageSize = 28;
  const iconSize = 16;

  const renderImageItems = (items, startIndex, endIndex) =>
    items
      .slice(startIndex, endIndex)
      .map(
        ({
          id: championId,
          name: championName,
          image_url: championImage,
          order: championOrder,
        }) => (
          <ImageItem
            key={`${id}${championOrder}${championId}`}
            src={championImage}
            alt={championName}
            width={championImageSize}
            height={championImageSize}
            noborder
          />
        ),
      );

  const renderDraftPicks = (picks, startIndex, endIndex, align) =>
    picks
      .slice(startIndex, endIndex)
      .map((el) => <DraftPick key={`${id}${el.order}${el.id}`} data={el} align={align} />);

  return (
    <Box style={{ backgroundColor: WHITE_A10 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        backgroundColor={GREEN_MAIN}
        sx={{ minHeight: 30 }}
      >
        <Typography variant="body2" component="span" sx={{ color: BLACK_MAIN }}>
          {data.date}
          {data.patch ? ` / Patch ${data.patch}` : ''}
        </Typography>
        {vod && <VODData value={vod} fontSize={iconSize} />}
        {replay && <ReplayData gameId={provider_id} color={BLACK_MAIN} fontSize={iconSize} />}
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid size={{ xs: 6 }}>
            <Box backgroundColor={is_blue_win ? GREEN_A10 : 'transparent'} paddingY={0.25}>
              <ImageItem
                src={blue.image}
                alt={blue.name}
                width={teamImageSize}
                height={teamImageSize}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Box backgroundColor={!is_blue_win ? GREEN_A10 : 'transparent'} paddingY={0.25}>
              <ImageItem
                src={red.image}
                alt={red.name}
                width={teamImageSize}
                height={teamImageSize}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={0}>
        <Grid size={{ xs: 6 }}>
          <Box backgroundColor={GRAY_A50} paddingY={0.25}>
            {renderImageItems(blue.bans, 0, 3)}
          </Box>
          <Box paddingY={0.25}>{renderDraftPicks(blue.picks, 0, 3, 'left')}</Box>
          <Box backgroundColor={GRAY_A50} paddingY={0.25}>
            {renderImageItems(blue.bans, 3, 6)}
          </Box>
          <Box paddingY={0.25}>{renderDraftPicks(blue.picks, 3, 6, 'left')}</Box>
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Box backgroundColor={GRAY_A50} paddingY={0.25}>
            {renderImageItems(red.bans, 0, 3)}
          </Box>
          <Box paddingY={0.25}>{renderDraftPicks(red.picks, 0, 3, 'right')}</Box>
          <Box backgroundColor={GRAY_A50} paddingY={0.25}>
            {renderImageItems(red.bans, 3, 6)}
          </Box>
          <Box paddingY={0.25}>{renderDraftPicks(red.picks, 3, 6, 'right')}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Draft.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Draft;
