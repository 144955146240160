// React
import React, { useContext, useState } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Context
import { Context } from 'context';

// Utils
import { secondsToHms } from 'utils/time';

// Constants
import {
  USER,
  MATCH_TYPES_ALL,
  MATCH_TYPES_S,
  MATCH_TYPES,
  RANGE_DATE,
  SOLOQ_RANGE_DATE,
  PICKS,
  ENEMY,
  SIDES,
  RESULTS,
  PATCHES,
  OPPONENTS,
  ROLES_ALL,
  ROLES,
  MULTI_ROLES,
  SYNERGIES,
  CURRENT_TIME,
  WARD_TYPE,
  PATH_TYPE,
  TIME_RANGE_TYPE,
  OBJECTIVE_TYPE,
  WARD_OBJECTIVE_TYPE,
  TEAM_OBJECTIVE_TYPE,
  DRAFT_FILTER,
  SUMMONER_TYPE,
  GRAPH_TEAM_TYPE,
  GRAPH_PLAYER_TYPE,
  GRAPH_DATA_PLAYER_TYPE,
  STATISTIC_TEAM_TYPE,
  PLAYER_MAIN_ROLE_TYPE,
  ATE_TYPE,
  SPORT_TYPE,
  SNACK_TYPE,
  BLITZ_TYPE,
  MULTI_TEAMS,
  MULTI_TAGS,
  MULTI_CHAMPIONS,
} from 'constants/select';
import { timeMarks, timerangeMinDistance } from 'constants/time';

// Elements
import { Box, Slider, FormControlLabel, Checkbox } from '@mui/material';
import Select from 'components/atoms/Select/Select';
import DraftFilter from 'components/molecules/DraftFilter/DraftFilter';
import DateRangePicker from 'components/molecules/DateRangePicker/DateRangePicker';
import MultiSelect from 'components/atoms/MultiSelect/MultiSelect';

// Component
const DataFilters = ({
  userFilter = [],
  matchTypeAllFilter = false,
  matchTypeSFilter = false,
  matchTypeFilter = false,
  rangeDateFilter = false,
  soloQRangeDateFilter = false,
  pickFilter = false,
  enemyFilter = false,
  sideFilter = false,
  resultFilter = false,
  patchesFilter = [],
  opponentsFilter = [],
  roleAllFilter = false,
  multiRoleFilter = false,
  roleFilter = false,
  synergyFilter = false,
  currentTimeFilter = false,
  wardTypeFilter = false,
  statisticTeamFilter = false,
  pathTypeFilter = false,
  timerangeFilter = false,
  objectivesFilter = false,
  wardObjectivesFilter = false,
  teamObjectiveFilter = false,
  summonersFilter = false,
  graphTeamTypeFilter = false,
  graphPlayerTypeFilter = false,
  graphDataPlayerTypeFilter = false,
  playerMainRoleFilter = false,
  ateFilter = false,
  sportFilter = false,
  snackFilter = false,
  blitzFilter = false,
  draftFilter = false,
  teamsFilter = [],
  championsFilter = [],
  tagsFilter = [],
  type,
}) => {
  const { filters, setFilters } = useContext(Context);
  const filtersList = [];

  const addFilterToList = (filter, value) => {
    filtersList.push({
      ...filter,
      value: filters[type][value],
    });
  };

  if (userFilter && userFilter.length > 0) {
    filtersList.push({
      ...USER,
      options: [...USER.options, ...userFilter],
      value: filters[type].user,
    });
  }

  if (matchTypeAllFilter) {
    addFilterToList(MATCH_TYPES_ALL, 'matchTypeAll');
  }

  if (matchTypeSFilter) {
    addFilterToList(MATCH_TYPES_S, 'matchTypeS');
  }

  if (matchTypeFilter) {
    addFilterToList(MATCH_TYPES, 'matchType');
  }

  if (rangeDateFilter) {
    addFilterToList(RANGE_DATE, 'rangeDate');
  }

  if (soloQRangeDateFilter) {
    addFilterToList(SOLOQ_RANGE_DATE, 'soloQRangeDate');
  }

  if (pickFilter) {
    addFilterToList(PICKS, 'pick');
  }

  if (enemyFilter) {
    addFilterToList(ENEMY, 'enemy');
  }

  if (sideFilter) {
    addFilterToList(SIDES, 'side');
  }

  if (resultFilter) {
    addFilterToList(RESULTS, 'result');
  }

  if (roleAllFilter) {
    addFilterToList(ROLES_ALL, 'roleAll');
  }

  if (roleFilter) {
    addFilterToList(ROLES, 'role');
  }

  if (multiRoleFilter) {
    addFilterToList(MULTI_ROLES, 'multirole');
  }

  if (synergyFilter) {
    addFilterToList(SYNERGIES, 'synergy');
  }

  if (currentTimeFilter) {
    addFilterToList(CURRENT_TIME, 'currentTime');
  }

  if (wardTypeFilter) {
    addFilterToList(WARD_TYPE, 'wardType');
  }

  if (statisticTeamFilter) {
    addFilterToList(STATISTIC_TEAM_TYPE, 'statisticTeam');
  }

  if (pathTypeFilter) {
    addFilterToList(PATH_TYPE, 'pathType');
  }

  if (objectivesFilter) {
    addFilterToList(OBJECTIVE_TYPE, 'objective');
  }

  if (wardObjectivesFilter) {
    addFilterToList(WARD_OBJECTIVE_TYPE, 'wardObjective');
  }

  if (teamObjectiveFilter) {
    addFilterToList(TEAM_OBJECTIVE_TYPE, 'teamObjective');
  }

  if (summonersFilter) {
    addFilterToList(SUMMONER_TYPE, 'summoner');
  }

  if (graphTeamTypeFilter) {
    addFilterToList(GRAPH_TEAM_TYPE, 'graphTeamType');
  }

  if (graphPlayerTypeFilter) {
    addFilterToList(GRAPH_PLAYER_TYPE, 'graphPlayerType');
  }

  if (graphDataPlayerTypeFilter) {
    addFilterToList(GRAPH_DATA_PLAYER_TYPE, 'graphDataPlayerType');
  }

  if (patchesFilter && patchesFilter.length > 0) {
    filtersList.push({
      ...PATCHES,
      options: [...PATCHES.options, ...patchesFilter],
      value: filters[type].patch,
    });
  }

  if (opponentsFilter && opponentsFilter.length > 0) {
    filtersList.push({
      ...OPPONENTS,
      options: [...OPPONENTS.options, ...opponentsFilter],
      value: filters[type].opponent,
    });
  }

  if (blitzFilter) {
    addFilterToList(BLITZ_TYPE, 'blitz');
  }

  if (playerMainRoleFilter) {
    addFilterToList(PLAYER_MAIN_ROLE_TYPE, 'playerMainRole');
  }

  if (ateFilter) {
    addFilterToList(ATE_TYPE, 'ate');
  }

  if (sportFilter) {
    addFilterToList(SPORT_TYPE, 'sport');
  }

  if (snackFilter) {
    addFilterToList(SNACK_TYPE, 'snack');
  }

  if (teamsFilter && teamsFilter.length > 0) {
    filtersList.push({
      ...MULTI_TEAMS,
      options: [...MULTI_TEAMS.options, ...teamsFilter],
      value: filters[type].teams,
    });
  }

  if (championsFilter && championsFilter.length > 0) {
    filtersList.push({
      ...MULTI_CHAMPIONS,
      options: [...MULTI_CHAMPIONS.options, ...championsFilter],
      value: filters[type].champions,
    });
  }

  if (tagsFilter && tagsFilter.length > 0) {
    filtersList.push({
      ...MULTI_TAGS,
      options: [...MULTI_TAGS.options, ...tagsFilter],
      value: filters[type].tags,
    });
  }

  if (draftFilter) {
    filtersList.push({
      ...DRAFT_FILTER,
    });
  }

  if (timerangeFilter) {
    addFilterToList(TIME_RANGE_TYPE, 'timerange');
  }

  const resetPatchAndOpponent = (id) => {
    const patchFilter = filtersList.find((item) => item.id === 'patch');
    const opponentFilter = filtersList.find((item) => item.id === 'opponent');

    if (id === 'matchTypeAll' || id === 'matchType' || id === 'rangeDate') {
      if (patchFilter) {
        patchFilter.value = 'ALL';

        setFilters((prevValue) => ({
          ...prevValue,
          [type]: { ...prevValue[type], patch: 'ALL' },
        }));
      }

      if (opponentFilter) {
        opponentFilter.value = 'ALL';

        setFilters((prevValue) => ({
          ...prevValue,
          [type]: { ...prevValue[type], opponent: 'ALL' },
        }));
      }
    }
  };

  const handleSelectChange = (id, newData) => {
    resetPatchAndOpponent(id);

    setFilters((prevValue) => ({
      ...prevValue,
      [type]: { ...prevValue[type], [id]: newData },
    }));
  };

  const handleCheckboxChange = (id, newData) => {
    setFilters((prevValue) => ({
      ...prevValue,
      [type]: { ...prevValue[type], [id]: newData },
    }));
  };

  return (
    <Box display="flex" flexWrap="wrap" gap={1} mb={1}>
      {filtersList.map(({ id, label, filterType, value, options, minWidth }) => {
        if (filterType === 'select') {
          let disabled = false;

          if ((id === 'patch' || id === 'opponent') && options.length === 1) {
            disabled = true;
          }

          return (
            <Select
              key={id}
              name={id}
              displayEmpty={false}
              disabled={options.length === 0 || disabled}
              label={label}
              optionLabel="label"
              options={options}
              value={value}
              compareValue="value"
              minWidth={minWidth}
              onChange={(e) => handleSelectChange(id, e.target.value)}
            />
          );
        }

        if (filterType === 'multiselect') {
          let disabled = false;

          if ((id === 'patch' || id === 'opponent') && options.length === 1) {
            disabled = true;
          }

          return (
            <MultiSelect
              key={id}
              name={id}
              disabled={options.length === 0 || disabled}
              label={label}
              optionLabel="label"
              options={options}
              value={value}
              compareValue="value"
              minWidth={minWidth}
              onChange={(e) =>
                setFilters((prevValue) => ({
                  ...prevValue,
                  [type]: { ...prevValue[type], [id]: e.target.value },
                }))
              }
            />
          );
        }

        if (filterType === 'rangeDate') {
          let disabled = false;
          let fieldName = 'rangeDate';

          if (id === 'rangeDate' && matchTypeAllFilter && filters[type].matchTypeAll === 'CAREER') {
            disabled = true;
          }

          if (id === 'soloQRangeDate') {
            fieldName = 'soloQRangeDate';
          }

          return (
            <DateRangePicker
              key={id}
              data={{
                start: {
                  id: 'startDate',
                  name: fieldName,
                  label: 'Start Date',
                  value: value[0],
                  disabled,
                },
                end: {
                  id: 'endDate',
                  name: fieldName,
                  label: 'End Date',
                  value: value[1],
                  disabled,
                },
              }}
              filter
              resetFn={resetPatchAndOpponent}
              type={type}
              wrap
            />
          );
        }

        if (filterType === 'timerange') {
          const [sliderTimerange, setSliderTimerange] = useState(value);

          const handleSliderChange = (_, newValue, activeThumb) => {
            let sliderValue = sliderTimerange;

            if (!Array.isArray(newValue)) {
              return;
            }

            if (activeThumb === 0) {
              sliderValue = [Math.min(newValue[0], value[1] - timerangeMinDistance), value[1]];
            } else {
              sliderValue = [value[0], Math.max(newValue[1], value[0] + timerangeMinDistance)];
            }

            setSliderTimerange(sliderValue);
          };

          const handleSliderChangeCommitted = () => {
            setFilters((prevValue) => ({
              ...prevValue,
              [type]: { ...prevValue[type], [id]: sliderTimerange },
            }));
          };

          return (
            <Box key={id} width="100%">
              <Box
                sx={{
                  width: { xs: '100%', xl: '50%' },
                  paddingLeft: { xs: 2, sm: 1 },
                  paddingRight: { xs: 2, sm: 1 },
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                <Slider
                  getAriaLabel={() => label}
                  min={0}
                  max={2100}
                  value={sliderTimerange}
                  valueLabelFormat={secondsToHms}
                  marks={timeMarks}
                  step={30}
                  onChange={handleSliderChange}
                  onChangeCommitted={handleSliderChangeCommitted}
                  valueLabelDisplay="auto"
                  getAriaValueText={secondsToHms}
                  disableSwap
                />
              </Box>
            </Box>
          );
        }

        if (filterType === 'checkbox') {
          let disabled = false;

          if (
            id === 'playerMainRole' &&
            ((matchTypeAllFilter && filters[type].matchTypeAll !== 'SOLOQ') ||
              (matchTypeSFilter &&
                filters[type].matchTypeS !== 'ALL' &&
                filters[type].matchTypeS !== 'SOLOQ'))
          ) {
            disabled = true;
          }

          return (
            <Box key={id} display="flex" alignItems="center">
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => handleCheckboxChange(id, e.target.checked)}
                  />
                }
                label={label}
              />
            </Box>
          );
        }

        if (filterType === 'draft') {
          return <DraftFilter key={id} type={type} />;
        }

        return null;
      })}
    </Box>
  );
};

DataFilters.propTypes = {
  userFilter: PropTypes.array,
  matchTypeAllFilter: PropTypes.bool,
  matchTypeSFilter: PropTypes.bool,
  matchTypeFilter: PropTypes.bool,
  rangeDateFilter: PropTypes.bool,
  soloQRangeDateFilter: PropTypes.bool,
  pickFilter: PropTypes.bool,
  enemyFilter: PropTypes.bool,
  sideFilter: PropTypes.bool,
  resultFilter: PropTypes.bool,
  patchesFilter: PropTypes.array,
  opponentsFilter: PropTypes.array,
  roleAllFilter: PropTypes.bool,
  roleFilter: PropTypes.bool,
  multiRoleFilter: PropTypes.bool,
  synergyFilter: PropTypes.bool,
  currentTimeFilter: PropTypes.bool,
  wardTypeFilter: PropTypes.bool,
  statisticTeamFilter: PropTypes.bool,
  pathTypeFilter: PropTypes.bool,
  timerangeFilter: PropTypes.bool,
  objectivesFilter: PropTypes.bool,
  wardObjectivesFilter: PropTypes.bool,
  teamObjectiveFilter: PropTypes.bool,
  summonersFilter: PropTypes.bool,
  graphTeamTypeFilter: PropTypes.bool,
  graphPlayerTypeFilter: PropTypes.bool,
  graphDataPlayerTypeFilter: PropTypes.bool,
  type: PropTypes.string.isRequired,
  playerMainRoleFilter: PropTypes.bool,
  ateFilter: PropTypes.bool,
  sportFilter: PropTypes.bool,
  snackFilter: PropTypes.bool,
  blitzFilter: PropTypes.bool,
  draftFilter: PropTypes.bool,
  teamsFilter: PropTypes.array,
  championsFilter: PropTypes.array,
  tagsFilter: PropTypes.array,
};

export default DataFilters;
