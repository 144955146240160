// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Elements
import { Grid2 as Grid, FormControlLabel, Checkbox } from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';
import Select from 'components/atoms/Select/Select';
import Switch from 'components/atoms/Switch/Switch';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';
import MultiAutocomplete from 'components/atoms/MultiAutocomplete/MultiAutocomplete';
import FileUpload from 'components/atoms/FileUpload/FileUpload';
import TeamsManageData from 'components/molecules/TeamsManageData/TeamsManageData';
import PerformanceManageData from 'components/molecules/PerformanceManageData/PerformanceManageData';
import DailyManageData from 'components/organisms/DailyManageData/DailyManageData';
import NationalitiesManageData from 'components/molecules/NationalitiesManageData/NationalitiesManageData';
import GameSelect from 'components/organisms/GameSelect/GameSelect';
import DraftsManageData from 'components/templates/DraftsManageData/DraftsManageData';

// Component
const AdminManageColumn = ({
  fields,
  size,
  touched,
  errors,
  values,
  handleChange,
  setFieldValue,
}) => (
  <Grid size={{ xs: 12, sm: 8, md: 6, lg: 4 * size, xl: 3 * size, xxl: 2 * size }}>
    {fields.map(
      ({
        id,
        label,
        optionLabel,
        typeField,
        type,
        options,
        displayEmpty,
        compareValue,
        disabled,
        dependsDisableField,
        dependsDisableValue,
        dependsDisplayField,
        dependsDisplayValue,
        loadData,
        isOptionEqualToValue,
        rows,
        multiline,
        freeSolo,
        newItemType,
        mt,
      }) => {
        if (typeField === 'textfield') {
          if (dependsDisplayField && values[dependsDisplayField] !== dependsDisplayValue) {
            return null;
          }

          return (
            <TextField
              key={id}
              disabled={
                (dependsDisableField && values[dependsDisableField] === dependsDisableValue) ||
                disabled
              }
              error={touched[id] && !!errors[id]}
              helperText={touched[id] && errors[id]}
              type={type}
              name={id}
              label={label}
              value={values[id]}
              onChange={handleChange}
              rows={rows}
              multiline={multiline}
              fullWidth
            />
          );
        }

        if (typeField === 'select') {
          if (dependsDisplayField && values[dependsDisplayField] !== dependsDisplayValue) {
            return null;
          }

          return (
            <Select
              key={id}
              disabled={
                disabled ||
                options.length === 0 ||
                (dependsDisableField && values[dependsDisableField] === dependsDisableValue)
              }
              error={touched[id] && !!errors[id]}
              helperText={touched[id] && errors[id]}
              name={id}
              label={label}
              optionLabel={optionLabel}
              options={options}
              displayEmpty={displayEmpty}
              value={values[id]}
              compareValue={compareValue}
              onChange={handleChange}
            />
          );
        }

        if (typeField === 'autocomplete') {
          if (dependsDisplayField && values[dependsDisplayField] !== dependsDisplayValue) {
            return null;
          }

          return (
            <Autocomplete
              key={id}
              id={id}
              label={label}
              options={options}
              onChange={(_, newValue) => {
                setFieldValue(id, newValue);
              }}
              disabled={
                disabled ||
                options.length === 0 ||
                (dependsDisableField && values[dependsDisableField] === dependsDisableValue)
              }
              error={touched[id] && !!errors[id]}
              helperText={touched[id] && errors[id]}
              value={values[id]}
              compareValue={compareValue}
              optionLabel={optionLabel}
            />
          );
        }

        if (typeField === 'multiautocomplete') {
          if (dependsDisplayField && values[dependsDisplayField] !== dependsDisplayValue) {
            return null;
          }

          return (
            <MultiAutocomplete
              key={id}
              disabled={
                disabled ||
                (options.length === 0 && disabled !== false) ||
                (dependsDisableField && values[dependsDisableField] === dependsDisableValue)
              }
              name={id}
              label={label}
              optionLabel={optionLabel}
              options={options}
              value={values[id]}
              compareValue={compareValue}
              onChangeFunction={setFieldValue}
              onChangeField={id}
              isOptionEqualToValue={isOptionEqualToValue}
              freeSolo={freeSolo}
              newItemType={newItemType}
            />
          );
        }

        if (typeField === 'checkbox') {
          if (dependsDisplayField && values[dependsDisplayField] !== dependsDisplayValue) {
            return null;
          }

          return (
            <FormControlLabel
              key={id}
              disabled={
                disabled ||
                (dependsDisableField && values[dependsDisableField] === dependsDisableValue)
              }
              control={<Checkbox id={id} name={id} />}
              label={label}
              checked={values[id]}
              onChange={handleChange}
            />
          );
        }

        if (typeField === 'file') {
          return <FileUpload key={id} id={id} label={label} onChange={setFieldValue} />;
        }

        if (typeField === 'switch') {
          return (
            <Switch
              key={id}
              id={id}
              label={label}
              value={values[id]}
              type={type}
              mt={mt}
              onChange={setFieldValue}
            />
          );
        }

        if (typeField === 'nationalities') {
          if (
            (dependsDisplayField && values[dependsDisplayField] !== dependsDisplayValue) ||
            loadData.nationalities.length === 0
          ) {
            return null;
          }

          return (
            <NationalitiesManageData
              key={id}
              value={values.nationalities}
              disabled={disabled}
              loadData={loadData}
              setFieldValue={setFieldValue}
            />
          );
        }

        if (typeField === 'teams') {
          if (
            (dependsDisplayField && values[dependsDisplayField] !== dependsDisplayValue) ||
            loadData.teams.length === 0
          ) {
            return null;
          }

          return (
            <TeamsManageData
              key={id}
              value={values.teams}
              disabled={disabled}
              loadData={loadData}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          );
        }

        if (typeField === 'performance') {
          const component = [];

          for (let i = 0; i < 5; i += 1) {
            const tempId = `player_${i + 1}`;
            const tempLabel = `Player ${i + 1}`;
            const tempSportId = `${tempId}_sport`;
            const tempLunchId = `${tempId}_lunch`;
            const tempSnackId = `${tempId}_snack`;
            const tempDinnerId = `${tempId}_dinner`;

            component.push(
              <PerformanceManageData
                key={tempId}
                id={tempId}
                label={tempLabel}
                sportId={tempSportId}
                lunchId={tempLunchId}
                snackId={tempSnackId}
                dinnerId={tempDinnerId}
                disabled={disabled}
                values={values}
                options={options}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />,
            );
          }

          return component;
        }

        if (typeField === 'daily') {
          return (
            <DailyManageData
              key="dailyData"
              value={values.data}
              loadData={loadData}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          );
        }

        if (typeField === 'game') {
          return (
            <GameSelect
              key={id}
              id={id}
              value={values[id]}
              setFieldValue={setFieldValue}
              loadData={loadData}
            />
          );
        }

        if (typeField === 'drafts') {
          if (
            (dependsDisplayField && values[dependsDisplayField] !== dependsDisplayValue) ||
            loadData.champions.length === 0
          ) {
            return null;
          }

          return (
            <DraftsManageData
              key={id}
              value={values.drafts}
              loadData={loadData}
              setFieldValue={setFieldValue}
            />
          );
        }

        return null;
      },
    )}
  </Grid>
);

AdminManageColumn.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      typeField: PropTypes.string.isRequired,
      type: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
  ).isRequired,
  size: PropTypes.number.isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default AdminManageColumn;
