// Styles
import styled from 'styled-components';

// Assets
import ClearIcon from '@mui/icons-material/Clear';

export const StyledTooltipBox = styled.div`
  && {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledImage = styled.img`
  && {
    display: flex;
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'initial')};
    max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'initial')};
    border: ${({ theme, side, noborder }) => {
      let border = '2px solid transparent';

      if (noborder) {
        border = 'none';
      }

      if (side) {
        border = `2px solid ${theme.palette[side.toLowerCase()].main}`;
      }

      return border;
    }};
    margin: ${({ noborder }) => (noborder ? '1px' : '0')};
  }
`;

export const StyledClearIcon = styled(ClearIcon)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.red.main};
  }
`;
