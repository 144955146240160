// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Recharts
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, LabelList } from 'recharts';

// Constants
import { BLUE_MAIN } from 'constants/color';

// Elements
import BarLabel from 'components/atoms/BarLabel/BarLabel';

// Component
const ChartBar = ({
  data = [],
  color = BLUE_MAIN,
  highValue = 0,
  vertical = false,
  inverse = false,
  yTick = null,
}) => {
  const domain = [0, Math.ceil(highValue / 10000) * 10000];
  const orientation = inverse ? 'right' : 'left';

  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <BarChart data={data} layout={vertical ? 'vertical' : 'horizontal'}>
        <XAxis hide type="number" domain={domain} reversed={inverse} />
        <YAxis
          tickLine={false}
          axisLine={false}
          type="category"
          dataKey="image"
          tick={yTick}
          orientation={orientation}
        />
        <Bar dataKey="value" fill={color} isAnimationActive={false}>
          <LabelList content={<BarLabel inverse={inverse} postFix="K" />} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

ChartBar.propTypes = {
  data: PropTypes.array,
  color: PropTypes.string,
  highValue: PropTypes.number,
  vertical: PropTypes.bool,
  inverse: PropTypes.bool,
  yTick: PropTypes.node,
};

export default ChartBar;
