// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Services
import { fetchDailyReport } from 'services/daily';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import { Grid2 as Grid } from '@mui/material';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import DailyCard from 'components/organisms/DailyCard/DailyCard';

const DailyReportView = () => {
  const {
    setTitleSEO,
    filters: {
      daily: { rangeDate },
    },
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);
  const title = 'Daily Report';

  const fetchData = (startDateData, endDateData) => {
    setIsLoading(true);

    fetchDailyReport(startDateData, endDateData).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(rangeDate[0], rangeDate[1]);
    }
  }, [rangeDate]);

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters rangeDateFilter type="daily" />
          {data && Object.keys(data).length > 0 && (
            <Grid container>
              {Object.keys(data).map((day) => (
                <Grid key={day} size={{ xs: 12, lg: 6 }}>
                  <DailyCard title={day} data={data[day]} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      }
    />
  );
};

export default DailyReportView;
