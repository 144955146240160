// React
import React, { useEffect, useRef, useCallback } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { BLUE_MAIN, WHITE_MAIN, RED_MAIN, GREEN_MAIN, BLACK_MAIN } from 'constants/color';

// Utils
import { centerTextInCanvas, drawWards, drawPositions } from 'utils/canvas';
import { mapPosition } from 'utils/map';
import { secondsToHms } from 'utils/time';

// Constants
import { BLUE } from 'constants/side';
import { RECALL, DEATH } from 'constants/jungle';
import { CANVAS_PROPORTION, CANVAS_WARD_FONT_SIZE } from 'constants/canvas';

// Assets
import mapImage from 'assets/img/map.png';

// Elements
import ResultBox from 'components/atoms/ResultBox/ResultBox';

// Styles
import { StyledMap, StyledCanvas, StyledCount } from 'assets/styles/Map.styles';
import { StyledBox, StyledInfo, StyledTimer } from './GameMap.styles';

// Component
const GameMap = ({
  date = null,
  opponent = null,
  win = null,
  side = null,
  positions = null,
  path = null,
  wards = null,
  currentWard = null,
  picks = null,
  teleport = null,
  count = null,
  time = null,
}) => {
  const mapRef = useRef(null);
  const canvasRef = useRef(null);

  const drawMap = useCallback(() => {
    const mapCanvas = canvasRef.current;

    if (mapCanvas) {
      const mapItem = mapCanvas.parentElement;
      const mapWidth = mapItem.clientWidth;
      const mapHeight = mapItem.clientHeight;

      mapCanvas.width = mapWidth;
      mapCanvas.height = mapHeight;

      const ctx = mapCanvas.getContext('2d');
      const scale = Math.min(mapWidth, mapHeight) / CANVAS_PROPORTION;
      const fontSize = CANVAS_WARD_FONT_SIZE * scale;

      if (positions && positions.length > 0) {
        let countPath = 0;

        positions.forEach(({ x, y }) => {
          const newPosPlayerX = mapPosition(x, mapWidth);
          const newPosPlayerY = mapPosition(y, mapHeight);

          ctx.setLineDash([10 * scale, 5 * scale]);
          ctx.lineWidth = 1 * scale;
          ctx.strokeStyle = WHITE_MAIN;

          if (countPath === 0) {
            ctx.beginPath();
            ctx.moveTo(newPosPlayerX, mapHeight - newPosPlayerY);
          } else {
            ctx.lineTo(newPosPlayerX, mapHeight - newPosPlayerY);
            ctx.stroke();
          }

          countPath += 1;
        });
      }

      if (path && path.length > 0) {
        let countP = 1;

        path.forEach(({ name, x, y }) => {
          const newPosX = mapPosition(x, mapWidth);
          const newPosY = mapPosition(y, mapHeight);
          let circleBG = RED_MAIN;

          if (side && side === BLUE) {
            circleBG = BLUE_MAIN;
          }

          let circleText = countP;

          if (name === RECALL) {
            circleBG = GREEN_MAIN;
            circleText = 'R';
          } else if (name === DEATH) {
            circleBG = BLACK_MAIN;
            circleText = 'D';
          }

          ctx.setLineDash([]);
          ctx.beginPath();
          ctx.fillStyle = circleBG;
          ctx.arc(newPosX, mapHeight - newPosY, 14 * scale, 0, 2 * Math.PI);
          ctx.fill();
          ctx.stroke();

          centerTextInCanvas(ctx, fontSize);
          ctx.fillStyle = WHITE_MAIN;
          ctx.fillText(circleText, newPosX, mapHeight - newPosY);

          countP += 1;
        });
      }

      if (teleport && Object.keys(teleport).length > 0) {
        const { x: startX, y: startY } = teleport.start;
        const newStartPosX = mapPosition(startX, mapWidth);
        const newStartPosY = mapPosition(startY, mapHeight);

        ctx.setLineDash([]);
        ctx.beginPath();
        ctx.fillStyle = RED_MAIN;
        ctx.arc(newStartPosX, mapHeight - newStartPosY, 14 * scale, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();

        centerTextInCanvas(ctx, fontSize);
        ctx.fillStyle = WHITE_MAIN;
        ctx.fillText('1', newStartPosX, mapHeight - newStartPosY);

        const { x: endX, y: endY } = teleport.end;
        const newEndPosX = mapPosition(endX, mapWidth);
        const newEndPosY = mapPosition(endY, mapHeight);

        ctx.setLineDash([]);
        ctx.beginPath();
        ctx.fillStyle = GREEN_MAIN;
        ctx.arc(newEndPosX, mapHeight - newEndPosY, 14 * scale, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();

        centerTextInCanvas(ctx, fontSize);
        ctx.fillStyle = WHITE_MAIN;
        ctx.fillText('2', newEndPosX, mapHeight - newEndPosY);
      }

      if (wards && wards.length > 0) {
        drawWards(ctx, wards, mapWidth, mapHeight, scale, currentWard);
      }

      if (picks && picks.length > 0) {
        drawPositions(ctx, picks, mapWidth, mapHeight, scale);
      }
    }
  }, [positions, path, wards, currentWard, picks, teleport]);

  useEffect(() => {
    drawMap();

    const handleResize = () => {
      drawMap();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [drawMap]);

  return (
    <StyledMap image={mapImage} side={side} ref={mapRef}>
      {count !== null ? (
        <StyledCount variant="body2" component="p">
          {count}
        </StyledCount>
      ) : null}
      {date && opponent ? (
        <StyledBox>
          <StyledInfo>{`${date} ${opponent}`}</StyledInfo>
          {win !== undefined ? <ResultBox win={win} /> : null}
        </StyledBox>
      ) : null}
      {time ? <StyledTimer>{secondsToHms(time)}</StyledTimer> : null}
      <StyledCanvas ref={canvasRef} />
    </StyledMap>
  );
};

GameMap.propTypes = {
  date: PropTypes.string,
  opponent: PropTypes.string,
  win: PropTypes.bool,
  side: PropTypes.string,
  positions: PropTypes.array,
  path: PropTypes.array,
  wards: PropTypes.array,
  currentWard: PropTypes.number,
  picks: PropTypes.array,
  teleport: PropTypes.object,
  count: PropTypes.number,
  time: PropTypes.number,
};

export default GameMap;
