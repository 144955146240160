// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { adminUsersURL } from 'routes/admin';

// Services
import { fetchSingleUser, updateUser } from 'services/users';
import { fetchTeams } from 'services/teams';
import { fetchPlayers } from 'services/players';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { USER_ROLES } from 'constants/user';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const AdminUsersEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);
  const [playerOptions, setPlayerOptions] = useState([]);
  const [currentAccessTeams, setCurrentAccessTeams] = useState([]);
  const [currentHomePlayers, setCurrentHomePlayers] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchSingleUser(id).then((res) => {
      setData(res);

      const accessTeams = res.teams_access.map((team) => team.team);
      const homePlayers = res.players_home.map((player) => player.player);

      setCurrentAccessTeams(accessTeams);
      setCurrentHomePlayers(homePlayers);

      setIsLoading(false);
    });

    fetchTeams().then((res) => setTeamOptions(res));
    fetchPlayers().then((res) => setPlayerOptions(res));
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO(`Edit ${data?.username} - Users ${ADMIN_TITLE_POSTFIX}`);
    }
  }, [data]);

  const initialValues = {
    username: data?.username || '',
    is_verified: data?.is_verified || false,
    role: data?.role || 'USER',
    teams_access: currentAccessTeams || [],
    players_home: currentHomePlayers || [],
    user_object: data?.user_object || null,
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
    is_verified: Yup.boolean(),
    role: Yup.string().required('Required'),
    teams_access: Yup.array(),
    players_home: Yup.array(),
    user_object: Yup.object().nullable(),
  });
  const fields1 = [
    {
      id: 'username',
      label: 'Username',
      typeField: 'textfield',
    },
    {
      id: 'is_verified',
      label: 'Verified',
      typeField: 'checkbox',
    },
    {
      id: 'role',
      label: 'Role',
      typeField: 'select',
      options: USER_ROLES,
      optionLabel: 'label',
      compareValue: 'value',
      displayEmpty: false,
    },
  ];
  const fields2 = [
    {
      id: 'teams_access',
      label: 'Access',
      typeField: 'multiautocomplete',
      options: teamOptions,
    },
    {
      id: 'players_home',
      label: 'Players home page',
      typeField: 'multiautocomplete',
      options: playerOptions,
    },
    {
      id: 'user_object',
      label: 'User object',
      typeField: 'autocomplete',
      options: playerOptions,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFn={updateUser}
      formikAdditionalFnArgs={[id]}
      formikRedirect={adminUsersURL}
      formikBtnText="Update user"
    />
  );
};

export default AdminUsersEditView;
