// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Services
import {
  fetchChampionsQueueGameErrors,
  deleteChampionsQueueGameError,
  deleteAllChampionsQueueGameError,
} from 'services/championsQueueGameErrors';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ADMIN_CHAMPIONS_QUEUE_GAME_ERRORS_COLS } from 'constants/tableHead';

// Assets
import ClearIcon from '@mui/icons-material/Clear';

// Elements
import AdminList from 'components/molecules/AdminList/AdminList';

const AdminChampionsQueueGameErrorsView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const renderAfterCalled = useRef(false);
  const title = `Champions Queue Game Errors ${ADMIN_TITLE_POSTFIX}`;

  const fetchData = () => {
    setIsLoading(true);

    fetchChampionsQueueGameErrors().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  const deleteAll = () => {
    setBtnLoading(true);

    deleteAllChampionsQueueGameError().then((res) => {
      let variantSnackbar = 'success';

      if (res.error) {
        variantSnackbar = 'error';
      }

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      fetchData();

      setBtnLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const buttons = [
    {
      id: 1,
      loadingState: btnLoading,
      loadingText: 'Deleting...',
      btnText: 'Delete all',
      btnIcon: <ClearIcon fontSize="small" />,
      btnFn: deleteAll,
    },
  ];

  return (
    <AdminList
      buttons={buttons}
      title={title}
      tableCols={ADMIN_CHAMPIONS_QUEUE_GAME_ERRORS_COLS}
      tableData={data}
      field="error"
      editBtn={false}
      deleteFn={deleteChampionsQueueGameError}
      refreshFn={fetchData}
    />
  );
};

export default AdminChampionsQueueGameErrorsView;
