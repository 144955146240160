// React
import React, { useEffect, useRef } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Heatmap.js
import * as heatMap from 'heatmap.js';

// Assets
import mapImage from 'assets/img/map.png';

// Styles
import { StyledMap, StyledCount } from 'assets/styles/Map.styles';

// Component
const Heatmap = ({ data, count = null }) => {
  const heatmapRef = useRef(null);

  useEffect(() => {
    const heatmapBox = heatmapRef.current;
    const heatmapWidth = heatmapBox.clientWidth;
    const heatmapHeight = heatmapBox.clientHeight;
    const firstCanvas = heatmapBox.querySelector('canvas');

    if (firstCanvas) {
      firstCanvas.remove();
    }

    const heatmapInstance = heatMap.create({
      container: heatmapBox,
    });

    const drawHeatmap = () => {
      if (heatmapBox) {
        const scale = 1;
        const radius = 10 * scale;
        const newDataObject = {};

        data.forEach(({ x, y, ward: type }) => {
          if (type && type === 'unknown') {
            return;
          }

          const xc = Math.round(x * heatmapWidth * 100) / 100;
          const yc = heatmapHeight - Math.round(y * heatmapWidth * 100) / 100;
          const xcFixed = xc.toFixed(0);
          const ycFixed = yc.toFixed(0);
          const newKeyObj = `${xcFixed}|${ycFixed}`;

          if (!(newKeyObj in newDataObject)) {
            newDataObject[newKeyObj] = {
              x: xcFixed,
              y: ycFixed,
              value: 0,
              radius,
            };
          }

          newDataObject[newKeyObj].value += 1;
        });

        const newDataPoints = Object.values(newDataObject);
        const maxLimit = 15;

        let max = Math.max(...newDataPoints.map(({ value }) => value));

        if (max > maxLimit) {
          max = maxLimit;
        }

        const dataPoints = {
          max,
          data: newDataPoints,
        };

        heatmapInstance.setData(dataPoints);
      }

      return true;
    };

    drawHeatmap();
  }, [data]);

  return (
    <StyledMap ref={heatmapRef} image={mapImage} heatmap>
      {count !== null && (
        <StyledCount variant="body2" component="p">
          {count}
        </StyledCount>
      )}
    </StyledMap>
  );
};

Heatmap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  count: PropTypes.number,
};

export default Heatmap;
