// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { WHITE_MAIN } from 'constants/color';

// Component
const BarLabel = ({ x = 0, y = 0, height = 0, value = 0, inverse = false, postFix = '' }) => {
  const newValue = Math.round((value / 1000) * 10) / 10;
  const textX = inverse ? x - 5 : x + 5;
  const textY = y + height / 2 + 1;
  const textAnchor = inverse ? 'end' : 'start';

  return (
    <text
      x={textX}
      y={textY}
      fontSize={16}
      fill={WHITE_MAIN}
      textAnchor={textAnchor}
      dominantBaseline="middle"
    >
      {newValue.toFixed(1)}
      {postFix}
    </text>
  );
};

BarLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  height: PropTypes.number,
  value: PropTypes.number,
  inverse: PropTypes.bool,
  postFix: PropTypes.string,
};

export default BarLabel;
