// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Elements
import { Tooltip } from '@mui/material';

// Placeholder
import placeholder from 'assets/img/placeholder.png';

// Styles
import { StyledTooltipBox, StyledImage, StyledClearIcon } from './ImageItem.styles';

// Component
const ImageItem = ({
  src = '',
  alt = 'Placeholder',
  side = '',
  ban = false,
  width = 40,
  maxWidth = null,
  height = 40,
  maxHeight = null,
  autoWidth = false,
  autoHeight = false,
  noborder = false,
}) => (
  <Tooltip title={alt}>
    <StyledTooltipBox>
      <StyledImage
        src={src || placeholder}
        alt={alt}
        side={side}
        width={autoWidth ? null : width}
        maxWidth={maxWidth}
        height={autoHeight ? null : height}
        maxHeight={maxHeight}
        noborder={+noborder}
      />
      {ban ? <StyledClearIcon /> : null}
    </StyledTooltipBox>
  </Tooltip>
);

ImageItem.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  side: PropTypes.string,
  ban: PropTypes.bool,
  width: PropTypes.number,
  maxWidth: PropTypes.number,
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  autoWidth: PropTypes.bool,
  autoHeight: PropTypes.bool,
  noborder: PropTypes.bool,
};

export default ImageItem;
