// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Utils
import { generateUniqueKey } from 'utils/key';

// Assets
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// Elements
import { Grid2 as Grid, Box, Typography, Button, IconButton } from '@mui/material';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';

// Component
const NationalitiesManageData = ({
  value,
  disabled = false,
  loadData = {
    nationalities: [],
  },
  setFieldValue,
}) => {
  const handleTeamChange = (nationalityIndex, field, newValue) => {
    setFieldValue(`nationalities[${nationalityIndex}].${field}`, newValue);
  };

  const addNewNationality = () => {
    setFieldValue('nationalities', [
      ...value,
      {
        key: generateUniqueKey(),
        nationality_id: '',
        nationality: null,
      },
    ]);
  };

  const removeNationality = (index) => {
    const updatedNationalities = [...value];
    updatedNationalities.splice(index, 1);

    setFieldValue('nationalities', updatedNationalities);
  };

  return (
    <Box>
      <Typography variant="subtitle1">Nationalities</Typography>
      {loadData.nationalities.length > 0 ? (
        <Button variant="text" size="small" startIcon={<AddIcon />} onClick={addNewNationality}>
          Add nationality
        </Button>
      ) : null}
      {value.map(({ key, nationality_id }, nationalityIndex) => (
        <Grid container key={key || nationality_id}>
          <Grid size={{ xs: 12, md: 10 }}>
            <Grid container spacing={0} rowSpacing={0} columnSpacing={2}>
              <Grid size={{ xs: 12 }}>
                <Autocomplete
                  id={value[nationalityIndex].nationality_id.toString()}
                  label="Nationality"
                  options={loadData.nationalities}
                  onChange={(_, newValue) => {
                    handleTeamChange(nationalityIndex, 'nationality_id', newValue.id);
                    handleTeamChange(nationalityIndex, 'nationality', newValue);
                  }}
                  disabled={disabled || loadData.nationalities.length === 0}
                  value={value[nationalityIndex].nationality || null}
                  compareValue="id"
                  optionLabel="name"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12, md: 2 }}>
            <Box mt={3}>
              <IconButton aria-label="delete" onClick={() => removeNationality(nationalityIndex)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

NationalitiesManageData.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  loadData: PropTypes.shape({
    nationalities: PropTypes.arrayOf(PropTypes.object),
  }),
  setFieldValue: PropTypes.func.isRequired,
};

export default NationalitiesManageData;
