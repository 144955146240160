export const DRAFT_BLUE_FIELDS = [
  'bb1',
  'bb3',
  'bb5',
  'bp1',
  'bp4',
  'bp5',
  'bb8',
  'bb10',
  'bp8',
  'bp9',
];
export const DRAFT_RED_FIELDS = [
  'rb2',
  'rb4',
  'rb6',
  'rp2',
  'rp3',
  'rp6',
  'rb7',
  'rb9',
  'rp7',
  'rp10',
];
export const DRAFT_FIELDS = DRAFT_BLUE_FIELDS.concat(DRAFT_RED_FIELDS);
