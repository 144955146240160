// React
import React, { useContext } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Context
import { Context } from 'context';

// Dayjs
import dayjs from 'dayjs';

// Elements
import { DatePicker as DatePickerMUI } from '@mui/x-date-pickers/DatePicker';

// Component
const DatePikcer = ({
  id = '',
  name,
  label,
  value = null,
  onChangeFn = null,
  disabled = false,
  filter = false,
  resetFn = null,
  type = '',
  startValue = null,
  endValue = null,
}) => {
  const { setFilters } = useContext(Context);

  const handleDateChange = (newValue) => {
    let newInput = newValue;

    if (!dayjs(newValue).isValid()) {
      newInput = null;
    }

    if (filter) {
      let newData = [newInput, endValue];

      if (id === 'endDate') {
        newData = [startValue, newInput];
      }

      resetFn(id);

      setFilters((prevValue) => ({
        ...prevValue,
        [type]: { ...prevValue[type], [name]: newData },
      }));
    } else {
      onChangeFn(name, newInput);
    }
  };

  return (
    <DatePickerMUI
      slotProps={{
        textField: {
          id,
        },
      }}
      disabled={disabled}
      label={label}
      value={value}
      format="YYYY/MM/DD"
      onChange={handleDateChange}
      sx={{ width: filter ? 165 : '100%' }}
    />
  );
};

DatePikcer.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChangeFn: PropTypes.func,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  filter: PropTypes.bool,
  resetFn: PropTypes.func,
  type: PropTypes.string,
  startValue: PropTypes.object,
  endValue: PropTypes.object,
};

export default DatePikcer;
