// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const DraftData = ({ cell = null, value = null }) => {
  const cellValue = cell && cell.getValue();
  const data = value || cellValue;

  if (!data) {
    return null;
  }

  const images = data.map(({ id, name, image_url, side, ban }) => {
    const key = ban ? `ban_${id}` : id;

    return <ImageItem key={key} src={image_url} alt={name} side={side} ban={ban} />;
  });

  return (
    <Box display="flex" gap={0.25}>
      {images}
    </Box>
  );
};

DraftData.propTypes = {
  cell: PropTypes.object,
  value: PropTypes.array,
};

export default DraftData;
