// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { GREEN_MAIN, RED_MAIN } from 'constants/color';
import { FREE_DAY_TEXT, GAME_DAY_TEXT } from 'constants/text';

// Elements
import { Typography, Grid2 as Grid, Box, Card, CardContent, Chip } from '@mui/material';
import GameDiffData from 'components/atoms/GameDiffData/GameDiffData';
import GameDurationData from 'components/atoms/GameDurationData/GameDurationData';

const SoloqCard = ({
  title = '',
  data,
  background = null,
  freeDay = false,
  gameDay = false,
  goalCard = false,
  gameDuration = false,
  threshold = null,
}) => {
  const renderTitle = () => {
    if (goalCard) {
      return (
        <Grid container>
          <Grid size={{ xs: 6 }}>
            <Typography variant="body2" component="p" color="text.secondary">
              Goal
            </Typography>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Typography variant="body2" component="p" color="text.secondary">
              Yes
            </Typography>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Typography variant="body2" component="p" color="text.secondary">
              No
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Typography variant="body2" component="p" color="text.secondary" mr={1}>
        {title}
      </Typography>
    );
  };

  const renderChip = (label, color) => (
    <Chip label={label} color={color} size="small" sx={{ fontSize: 12 }} />
  );

  const renderPlayerData = (player) => {
    if (goalCard) {
      return (
        <Grid key={player} container>
          <Grid size={{ xs: 6 }}>
            <Typography variant="body2" component="p" mr={1}>
              {player}
            </Typography>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Typography variant="body2" component="p" color={GREEN_MAIN} mr={1}>
              {data[player].yes}
            </Typography>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Typography variant="body2" component="p" color={RED_MAIN}>
              {data[player].no}
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Box key={player} display="flex" alignItems="center">
        <Typography variant="body2" component="p" mr={1}>
          {player}:
        </Typography>
        {gameDuration ? (
          <GameDurationData value={data[player]} threshold={threshold || 7200} invert />
        ) : (
          <GameDiffData value={data[player]} />
        )}
      </Box>
    );
  };

  return (
    <Card
      style={{
        backgroundColor: background,
        height: '100%',
      }}
    >
      <CardContent>
        <Box display={!goalCard && 'flex'} alignItems={!goalCard && 'center'} mb={1}>
          {renderTitle()}
          {freeDay ? renderChip(FREE_DAY_TEXT, 'info') : null}
          {gameDay ? renderChip(GAME_DAY_TEXT, 'warning') : null}
        </Box>
        {data ? Object.keys(data).map((player) => renderPlayerData(player)) : null}
      </CardContent>
    </Card>
  );
};

SoloqCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  background: PropTypes.string,
  freeDay: PropTypes.bool,
  gameDay: PropTypes.bool,
  goalCard: PropTypes.bool,
  gameDuration: PropTypes.bool,
  threshold: PropTypes.number,
};

export default SoloqCard;
