// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Elements
import { TextField as TextFieldMUI } from '@mui/material';

// Component
const TextField = ({
  type = 'text',
  name = '',
  label = '',
  value,
  onChange,
  error = false,
  helperText = '',
  fullWidth = false,
  multiline = false,
  rows = 1,
  disabled = false,
}) => (
  <TextFieldMUI
    type={type}
    id={name}
    disabled={disabled}
    name={name}
    label={label}
    value={value}
    onChange={onChange}
    error={error}
    helperText={helperText}
    fullWidth={fullWidth}
    multiline={multiline}
    rows={rows}
    autoComplete="new-password"
  />
);

TextField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
};

export default TextField;
