// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { WHITE_MAIN, BLACK_MAIN } from 'constants/color';

// Utils
import { secondsToHms } from 'utils/time';

// Elements
import { Box, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const ChartTooltip = ({
  active = false,
  payload = [],
  label = '',
  additionalLabelText = '',
  tooltipKey = '',
  timeline = {},
  radar = false,
  positionMap = false,
}) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  const myLabel = payload[0].payload[tooltipKey] || label || payload[0].name;
  const imageSize = 28;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      bgcolor={WHITE_MAIN}
      color={BLACK_MAIN}
      p={1}
    >
      <Typography variant="body2" fontWeight="bold">
        {`${myLabel} ${additionalLabelText || ''}`}
      </Typography>
      {!positionMap ? (
        <>
          {payload.map((it) => (
            <Typography key={it.name} variant="body2">
              <>
                {radar ? `${it.name}: ` : ''}
                {it.payload[it.name]?.text || it?.value}
              </>
            </Typography>
          ))}
          {timeline && Object.keys(timeline).length > 0 && timeline[myLabel] ? (
            <Box mt={1}>
              <Typography variant="body2" fontWeight="bold">
                Events
              </Typography>
              {timeline[myLabel].map(({ name, time, side, lane, type }) => (
                <Box key={`${name}-${time}-${lane}-${type}`} display="flex" alignItems="center">
                  <ImageItem
                    src={`/img/timeline/${name.toLowerCase()}-${side}.png`}
                    alt={secondsToHms(time)}
                    width={imageSize}
                    height={imageSize}
                  />
                  <Typography variant="body2" component="p" ml={2}>
                    {secondsToHms(time)}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : null}
        </>
      ) : null}
    </Box>
  );
};

ChartTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionalLabelText: PropTypes.string,
  tooltipKey: PropTypes.string,
  timeline: PropTypes.object,
  radar: PropTypes.bool,
  positionMap: PropTypes.bool,
};

export default ChartTooltip;
