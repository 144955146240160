// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { RED_MAIN } from 'constants/color';

// Assets
import YouTubeIcon from '@mui/icons-material/YouTube';

// Elements
import { IconButton, Link } from '@mui/material';

// Component
const VODData = ({ cell = {}, value = '', fontSize = null }) => {
  const { getValue } = cell;
  const href = value || (cell && getValue());

  if (!href) {
    return null;
  }

  return (
    <IconButton aria-label="vod" component={Link} href={href} target="_blank" size="small">
      <YouTubeIcon sx={{ fontSize: fontSize || 20, color: RED_MAIN }} />
    </IconButton>
  );
};

VODData.propTypes = {
  cell: PropTypes.object,
  value: PropTypes.string,
  fontSize: PropTypes.number,
};

export default VODData;
