// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Elements
import { Box, Card, CardContent } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';
import CardTitle from 'components/atoms/CardTitle/CardTitle';
import CardValue from 'components/atoms/CardValue/CardValue';

// Component
const ChampionsCard = ({ title, data, imageSize = 32, bans = false }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <CardTitle text={title} />
      {data
        ? data.map(
            ({
              id: championId,
              name: championName,
              image: championImage,
              count: championCount,
              win_rate: championWinRate,
            }) => (
              <Box
                key={championId}
                display="grid"
                gridTemplateColumns={!bans ? `${imageSize}px 1fr 1fr` : `${imageSize}px 1fr`}
                alignItems="center"
              >
                <ImageItem
                  src={championImage}
                  alt={championName}
                  width={imageSize}
                  height={imageSize}
                  noborder
                />
                <CardValue value={championCount} center={!bans} right={bans} />
                {!bans ? (
                  <Box display="flex" justifyContent="flex-end">
                    <GamePercentageData value={championWinRate} fixNumbers />
                  </Box>
                ) : null}
              </Box>
            ),
          )
        : null}
    </CardContent>
  </Card>
);

ChampionsCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  imageSize: PropTypes.number,
  bans: PropTypes.bool,
};

export default ChampionsCard;
