// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchChampionsQueueGames = () =>
  axiosLoadingInstance
    .get('champions-queue-games/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const fetchSingleChampionsQueueGame = (id) =>
  axiosLoadingInstance
    .get(`champions-queue-games/${id}/`)
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const addChampionsQueueGame = (data) =>
  axiosInstance
    .post('champions-queue-games/add/', data)
    .then((res) => ({ data: res.data, message: 'Champions Queue Game has been added!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const updateChampionsQueueGame = (id, data) =>
  axiosInstance
    .put(`champions-queue-games/${id}/`, data)
    .then((res) => ({
      data: res.data,
      message: 'Champions Queue Game has been updated!',
      error: 0,
    }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteChampionsQueueGame = (id) =>
  axiosInstance
    .delete(`champions-queue-games/${id}/`)
    .then((res) => ({
      data: res.data,
      message: 'Champions Queue Game has been deleted!',
      error: 0,
    }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
