export const USER = 'USER';
export const PLAYER = 'PLAYER';
export const EDITOR = 'EDITOR';
export const ADMIN = 'ADMIN';

export const USER_ROLES = [
  {
    id: 1,
    value: USER,
    label: USER,
  },
  {
    id: 2,
    value: PLAYER,
    label: PLAYER,
  },
  {
    id: 3,
    value: EDITOR,
    label: EDITOR,
  },
  {
    id: 4,
    value: ADMIN,
    label: ADMIN,
  },
];
