// React
import React, { useState, useEffect, useRef } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Services
import { fetchTeams } from 'services/teams';
import { fetchGamesForLibrary } from 'services/library';

// Constants
import { LOADING_TEXT } from 'constants/text';
import { MATCH_TYPES_LIST } from 'constants/match';

// Schemas
import { OBJECT_NULLABLE_SCHEMA, STRING_REQUIRED_SCHEMA, STRING_SCHEMA } from 'schemas/base';

// Elements
import { Box, Grid2 as Grid } from '@mui/material';
import Select from 'components/atoms/Select/Select';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

// Component
const GameSelectSearch = ({ setData }) => {
  const [teams, setTeams] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    fetchTeams().then((res) => {
      setTeams(res);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    type: 'ALL',
    blue_id: '',
    blue: null,
    red_id: '',
    red: null,
  };

  const validationSchema = Yup.object().shape({
    type: STRING_REQUIRED_SCHEMA,
    blue_id: STRING_SCHEMA,
    blue: OBJECT_NULLABLE_SCHEMA,
    red_id: STRING_SCHEMA,
    red: OBJECT_NULLABLE_SCHEMA,
  });

  return (
    <Box mb={2}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setData([]);

          fetchGamesForLibrary(values).then((res) => {
            setData(res);

            setSubmitting(false);
          });
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container alignItems="center">
              <Grid size={{ xs: 12, sm: 6, xl: 3 }}>
                <Select
                  disabled={MATCH_TYPES_LIST.length === 0}
                  error={touched.type && !!errors.type}
                  helperText={touched.type && errors.type}
                  name="type"
                  label="Game type"
                  optionLabel="label"
                  options={MATCH_TYPES_LIST}
                  displayEmpty={false}
                  value={values.type}
                  compareValue="value"
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, xl: 3 }}>
                <Autocomplete
                  id="blue"
                  label="Blue"
                  options={teams}
                  onChange={(_, newValue) => {
                    setFieldValue('blue_id', newValue?.id);
                    setFieldValue('blue', newValue);
                  }}
                  disabled={teams.length === 0}
                  value={values.blue}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, xl: 3 }}>
                <Autocomplete
                  id="red"
                  label="Red"
                  options={teams}
                  onChange={(_, newValue) => {
                    setFieldValue('red_id', newValue?.id);
                    setFieldValue('red', newValue);
                  }}
                  disabled={teams.length === 0}
                  value={values.red}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 4, xl: 2 }}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={isSubmitting}
                  loadingIndicator={LOADING_TEXT}
                >
                  Show games
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

GameSelectSearch.propTypes = {
  setData: PropTypes.func.isRequired,
};

export default GameSelectSearch;
