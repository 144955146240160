// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Services
import { fetchSoloQCountReport } from 'services/soloq';

// Constants
import { GREEN_A10, BLUE_A10, WHITE_A10 } from 'constants/color';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import { Grid2 as Grid, Box } from '@mui/material';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import SoloqCard from 'components/molecules/SoloqCard/SoloqCard';

const SoloQCountReportView = () => {
  const {
    setTitleSEO,
    filters: {
      soloq: { rangeDate, playerMainRole },
    },
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState({});
  const renderAfterCalled = useRef(false);
  const title = 'SoloQ Count Report';

  const fetchData = (startDateData, endDateData, playerMainRoleData) => {
    setIsLoading(true);

    fetchSoloQCountReport(startDateData, endDateData, playerMainRoleData).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(rangeDate[0], rangeDate[1], playerMainRole);
    }
  }, [rangeDate, playerMainRole]);

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters rangeDateFilter playerMainRoleFilter type="soloq" />
          {data && Object.keys(data).length > 0 && Object.keys(data.days).length > 0 && (
            <>
              <Grid container>
                {Object.keys(data.days).map((day) => (
                  <Grid key={day} size={{ xs: 6, md: 3, lg: 12 / 7 }}>
                    <SoloqCard
                      title={day}
                      background={data.days[day].free || data.days[day].game_day ? GREEN_A10 : null}
                      data={data.days[day].data}
                      freeDay={data.days[day].free}
                      gameDay={data.days[day].game_day}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box mt={3}>
                <Grid container>
                  <Grid size={{ xs: 6, md: 3, lg: 12 / 7 }}>
                    <SoloqCard title="Total" background={BLUE_A10} data={data.summary} />
                  </Grid>
                  <Grid size={{ xs: 6, md: 3, lg: 12 / 7 }}>
                    <SoloqCard data={data.goal} background={WHITE_A10} goalCard />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      }
    />
  );
};

export default SoloQCountReportView;
