// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Constants
import { RED_A10, GREEN_A10 } from 'constants/color';

// Assets
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Elements
import { TextField, Checkbox, Autocomplete as AutocompleteMUI, Chip } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Component
const Autocomplete = ({
  id,
  label,
  options,
  onChange,
  disabled = false,
  error = false,
  helperText = '',
  value = null,
  compareValue = 'id',
  optionLabel = 'name',
  size = 'medium',
  type = null,
  isBan = false,
}) => {
  const handleAutocompleteChange = (_, newValue) => {
    onChange(id, newValue);
  };

  const renderTags = (el, getTagProps) =>
    el.map((option, index) => (
      <Chip key={option.id} label={option[optionLabel]} {...getTagProps({ index })} />
    ));

  let renderOption = (props, option, { selected }) => (
    <li {...props} key={option[optionLabel]}>
      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
      {option[optionLabel]}
    </li>
  );

  let renderInput = (params) => (
    <TextField {...params} label={label} error={error} helperText={helperText} />
  );

  if (type === 'draft') {
    renderOption = (props, option) => (
      <li {...props} key={option[optionLabel]}>
        {option[optionLabel]}
      </li>
    );
    renderInput = (params) => (
      <TextField
        {...params}
        margin="none"
        sx={{
          '& .MuiInputBase-root, & .MuiAutocomplete-hasPopupIcon': {
            padding: '0 !important',
          },
          '& .MuiInputBase-input': {
            fontSize: '0.875rem',
            backgroundColor: isBan ? RED_A10 : GREEN_A10,
            padding: '3px !important',
          },
          '& .MuiOutlinedInput-notchedOutline, & .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        }}
      />
    );
  }

  return (
    <AutocompleteMUI
      disabled={disabled}
      disableCloseOnSelect
      id={id}
      options={options}
      value={value}
      fullWidth
      onChange={handleAutocompleteChange}
      isOptionEqualToValue={(option, val) => option[compareValue] === val[compareValue]}
      renderTags={renderTags}
      getOptionLabel={(option) => option[optionLabel]}
      renderOption={renderOption}
      renderInput={renderInput}
      size={size}
      clearIcon={type === 'draft' ? null : undefined}
      popupIcon={type === 'draft' ? null : undefined}
    />
  );
};

Autocomplete.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.object,
  compareValue: PropTypes.string,
  optionLabel: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  isBan: PropTypes.bool,
};

export default Autocomplete;
