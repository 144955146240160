// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Grid2 as Grid } from '@mui/material';
import Sidebar from 'components/molecules/Sidebar/Sidebar';

// Component
const ViewWithSidebar = ({ component = null, menu = null, menu2 = null }) => (
  <Grid container spacing={4} sx={{ minHeight: { lg: 'calc(100vh - 64px)' } }}>
    <Grid size={{ xs: 12, lg: 2, xl: 1.5 }}>
      <Sidebar list={menu} list2={menu2} />
    </Grid>
    <Grid size={{ xs: 12, lg: 10, xl: 10.5 }}>{component}</Grid>
  </Grid>
);

ViewWithSidebar.propTypes = {
  component: PropTypes.node,
  menu: PropTypes.array,
  menu2: PropTypes.array,
};

export default ViewWithSidebar;
