// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Constants
import {
  TOP_MAIN,
  JNG_MAIN,
  MID_MAIN,
  BOT_MAIN,
  SUP_MAIN,
  WHITE_A10,
  BLACK_MAIN,
  WHITE_MAIN,
} from 'constants/color';

// Elements
import { Grid2 as Grid, TextField } from '@mui/material';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const DraftPreparationSide = ({
  data = [],
  index = 0,
  value,
  champions = [],
  handleDraftChange,
  invert = false,
}) => {
  const roleColor = {
    T: TOP_MAIN,
    J: JNG_MAIN,
    M: MID_MAIN,
    B: BOT_MAIN,
    A: BOT_MAIN,
    S: SUP_MAIN,
  };

  return (
    <Grid size={{ xs: 6 }}>
      {data.map((field) => {
        const fieldId = `${field}_id`;
        const roleValue = value[index][`${field}_role`];

        return (
          <Grid
            key={field}
            container
            flexDirection={invert ? 'row-reverse' : 'row'}
            alignItems="center"
            spacing={0}
          >
            <Grid size={{ xs: 2 }}>
              {value[index][field] ? (
                <ImageItem
                  src={value[index][field].image_url}
                  alt={value[index][field].name}
                  width={24}
                  height={24}
                  noborder
                />
              ) : null}
            </Grid>
            <Grid size={{ xs: 8 }}>
              <Autocomplete
                id={field}
                label={field.toUpperCase()}
                options={champions}
                onChange={(_, newValue) => {
                  handleDraftChange(index, field, newValue);
                  handleDraftChange(index, fieldId, newValue?.id);
                }}
                disabled={champions.length === 0}
                value={value[index][field] || null}
                compareValue="id"
                optionLabel="name"
                size="small"
                type="draft"
                isBan={field[1] === 'b'}
              />
            </Grid>
            {field[1] === 'p' ? (
              <Grid size={{ xs: 2 }}>
                <TextField
                  value={roleValue || ''}
                  slotProps={{
                    htmlInput: {
                      maxLength: 1,
                    },
                  }}
                  onChange={(e) => {
                    handleDraftChange(index, `${field}_role`, e.target.value.toUpperCase());
                  }}
                  margin="none"
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '0.875rem',
                      fontWeight: 700,
                      color: roleValue in roleColor ? BLACK_MAIN : WHITE_MAIN,
                      textAlign: 'center',
                      backgroundColor: roleValue in roleColor ? roleColor[roleValue] : WHITE_A10,
                      padding: '3px',
                    },
                    '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        border: 0,
                      },
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
        );
      })}
    </Grid>
  );
};

DraftPreparationSide.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  champions: PropTypes.arrayOf(PropTypes.object),
  handleDraftChange: PropTypes.func.isRequired,
  invert: PropTypes.bool,
};

export default DraftPreparationSide;
