// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Grid2 as Grid } from '@mui/material';
import Select from 'components/atoms/Select/Select';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';

// Component
const ChampionTeamField = ({ data }) => {
  const { champions, role, team } = data;

  const handleChampionChange = (_, newValue) => {
    champions.onChangeFn(champions.field, newValue);
  };

  return (
    <>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Autocomplete
          id={champions.field}
          label={champions.label}
          options={champions.data}
          onChange={handleChampionChange}
          disabled={champions.data.length === 0}
          error={champions.error}
          helperText={champions.helperText}
          value={champions.value}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Select
          disabled={role.data.length === 0}
          error={role.error}
          helperText={role.helperText}
          name={role.field}
          label="Role"
          options={role.data}
          optionLabel="label"
          displayEmpty={false}
          value={role.value}
          compareValue="value"
          onChange={role.onChangeFn}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Select
          disabled={team.data.length === 0}
          error={team.error}
          helperText={team.helperText}
          name={team.field}
          label="Team"
          options={team.data}
          displayEmpty={false}
          value={team.value}
          compareValue="value"
          onChange={team.onChangeFn}
        />
      </Grid>
    </>
  );
};

ChampionTeamField.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ChampionTeamField;
