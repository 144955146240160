// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Card, CardContent } from '@mui/material';
import ChartPie from 'components/organisms/ChartPie/ChartPie';
import CardTitle from 'components/atoms/CardTitle/CardTitle';

// Component
const CardPie = ({ title, data, type, chartKey = '' }) => (
  <Card>
    <CardContent>
      <CardTitle text={title} />
      <ChartPie data={data} type={type} chartKey={chartKey} />
    </CardContent>
  </Card>
);

CardPie.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  chartKey: PropTypes.string,
};

export default CardPie;
