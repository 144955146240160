// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Utils
import { generateUniqueKey } from 'utils/key';

// Assets
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// Elements
import { Grid2 as Grid, Box, Button, IconButton, Typography } from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';
import Autocomplete from 'components/atoms/Autocomplete/Autocomplete';

// Component
const MatchupsManageData = ({
  value,
  loadData = {
    players: [],
    champions: [],
    role: '',
  },
  handleChange,
  setFieldValue,
}) => {
  const handleMatchupChange = (matchupIndex, field, newValue) => {
    setFieldValue(`data[${matchupIndex}].${field}`, newValue);
  };

  const addNewMatchup = () => {
    setFieldValue('data', [
      ...value,
      {
        key: generateUniqueKey(),
        enemy_1: null,
        enemy_1_id: '',
        enemy_2: null,
        enemy_2_id: '',
        champion_1: null,
        champion_1_id: '',
        champion_2: null,
        champion_2_id: '',
        enemy_champion_1: null,
        enemy_champion_1_id: '',
        enemy_champion_2: null,
        enemy_champion_2_id: '',
        duration: '',
        notes: '',
      },
    ]);
  };

  const removeMatchup = (index) => {
    const updatedMatchups = [...value];
    updatedMatchups.splice(index, 1);

    setFieldValue('data', updatedMatchups);
  };

  let enemyLabel = 'Enemy';
  let championLabel = 'Champion';
  let enemyChampionLabel = 'Enemy champion';

  if (loadData.role === 'BOT' || loadData.role === 'SUP') {
    enemyLabel = 'Enemy BOT';
    championLabel = 'Champion BOT';
    enemyChampionLabel = 'Enemy champion BOT';
  }

  return (
    <Box>
      <Typography variant="subtitle1">Matchups</Typography>
      {loadData.players.length > 0 && loadData.champions.length > 0 ? (
        <Button variant="text" size="small" startIcon={<AddIcon />} onClick={addNewMatchup}>
          Add matchup
        </Button>
      ) : null}
      {value.map(({ key, duration, notes }, matchupIndex) => (
        <Box key={key} mt={matchupIndex > 0 ? 3 : 0}>
          <Grid container>
            <Grid size={{ xs: 12, md: 5 }}>
              <Grid container spacing={0} rowSpacing={0} columnSpacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Autocomplete
                    id={value[matchupIndex].enemy_1_id.toString()}
                    label={enemyLabel}
                    options={loadData.players}
                    onChange={(_, newValue) => {
                      handleMatchupChange(matchupIndex, 'enemy_1_id', newValue?.id);
                      handleMatchupChange(matchupIndex, 'enemy_1', newValue);
                    }}
                    disabled={loadData.players.length === 0}
                    value={value[matchupIndex].enemy_1 || null}
                    compareValue="id"
                    optionLabel="name"
                  />
                </Grid>
                {loadData.role === 'BOT' || loadData.role === 'SUP' ? (
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Autocomplete
                      id={value[matchupIndex].enemy_2_id.toString()}
                      label="Enemy SUP"
                      options={loadData.players}
                      onChange={(_, newValue) => {
                        handleMatchupChange(matchupIndex, 'enemy_2_id', newValue?.id);
                        handleMatchupChange(matchupIndex, 'enemy_2', newValue);
                      }}
                      disabled={loadData.players.length === 0}
                      value={value[matchupIndex].enemy_2 || null}
                      compareValue="id"
                      optionLabel="name"
                    />
                  </Grid>
                ) : (
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      name={`data[${matchupIndex}].duration`}
                      label="Duration"
                      value={duration || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid size={{ xs: 12, md: 6 }}>
                  <Autocomplete
                    id={value[matchupIndex].champion_1_id.toString()}
                    label={championLabel}
                    options={loadData.champions}
                    onChange={(_, newValue) => {
                      handleMatchupChange(matchupIndex, 'champion_1_id', newValue?.id);
                      handleMatchupChange(matchupIndex, 'champion_1', newValue);
                    }}
                    disabled={loadData.champions.length === 0}
                    value={value[matchupIndex].champion_1 || null}
                    compareValue="id"
                    optionLabel="name"
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Autocomplete
                    id={value[matchupIndex].enemy_champion_1_id.toString()}
                    label={enemyChampionLabel}
                    options={loadData.champions}
                    onChange={(_, newValue) => {
                      handleMatchupChange(matchupIndex, 'enemy_champion_1_id', newValue?.id);
                      handleMatchupChange(matchupIndex, 'enemy_champion_1', newValue);
                    }}
                    disabled={loadData.champions.length === 0}
                    value={value[matchupIndex].enemy_champion_1 || null}
                    compareValue="id"
                    optionLabel="name"
                  />
                </Grid>
                {loadData.role === 'BOT' || loadData.role === 'SUP' ? (
                  <>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <Autocomplete
                        id={value[matchupIndex].champion_2_id.toString()}
                        label="Champion SUP"
                        options={loadData.champions}
                        onChange={(_, newValue) => {
                          handleMatchupChange(matchupIndex, 'champion_2_id', newValue?.id);
                          handleMatchupChange(matchupIndex, 'champion_2', newValue);
                        }}
                        disabled={loadData.champions.length === 0}
                        value={value[matchupIndex].champion_2 || null}
                        compareValue="id"
                        optionLabel="name"
                      />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <Autocomplete
                        id={value[matchupIndex].enemy_champion_2_id.toString()}
                        label="Enemy champion SUP"
                        options={loadData.champions}
                        onChange={(_, newValue) => {
                          handleMatchupChange(matchupIndex, 'enemy_champion_2_id', newValue?.id);
                          handleMatchupChange(matchupIndex, 'enemy_champion_2', newValue);
                        }}
                        disabled={loadData.champions.length === 0}
                        value={value[matchupIndex].enemy_champion_2 || null}
                        compareValue="id"
                        optionLabel="name"
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid size={{ xs: 12, md: 5 }}>
              <Grid container spacing={0} rowSpacing={0} columnSpacing={2}>
                {loadData.role === 'BOT' || loadData.role === 'SUP' ? (
                  <Grid size={{ xs: 12 }}>
                    <TextField
                      name={`data[${matchupIndex}].duration`}
                      label="Duration"
                      value={duration || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                ) : null}
                <Grid size={{ xs: 12 }}>
                  <TextField
                    name={`data[${matchupIndex}].notes`}
                    label="Notes"
                    value={notes || ''}
                    onChange={handleChange}
                    rows={5}
                    multiline
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ xs: 12, md: 2 }}>
              <Box mt={2}>
                <IconButton aria-label="delete" onClick={() => removeMatchup(matchupIndex)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

MatchupsManageData.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadData: PropTypes.shape({
    players: PropTypes.arrayOf(PropTypes.object),
    champions: PropTypes.arrayOf(PropTypes.object),
    role: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default MatchupsManageData;
