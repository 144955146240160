// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Elements
import { Typography } from '@mui/material';

// Component
const CardTitle = ({ text, mt = 0 }) => (
  <Typography
    variant="body2"
    component="h3"
    fontWeight="bold"
    textAlign="center"
    mt={mt}
    gutterBottom
  >
    {text.toUpperCase()}
  </Typography>
);

CardTitle.propTypes = {
  text: PropTypes.string.isRequired,
  mt: PropTypes.number,
};

export default CardTitle;
