// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Services
import { fetchChampionsQueueGames, deleteChampionsQueueGame } from 'services/championsQueueGames';

// Constants
import { ADMIN_TITLE_POSTFIX } from 'constants/seo';
import { ADMIN_CHAMPIONS_QUEUE_GAMES_COLS } from 'constants/tableHead';

// Elements
import AdminList from 'components/molecules/AdminList/AdminList';

const AdminChampionsQueueGamesView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = `Champions Queue Games ${ADMIN_TITLE_POSTFIX}`;

  const fetchData = () => {
    setIsLoading(true);

    fetchChampionsQueueGames().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  return (
    <AdminList
      title={title}
      tableCols={ADMIN_CHAMPIONS_QUEUE_GAMES_COLS}
      tableData={data}
      deleteFn={deleteChampionsQueueGame}
      refreshFn={fetchData}
      editBtn={false}
    />
  );
};

export default AdminChampionsQueueGamesView;
