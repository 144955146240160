// React
import React, { useRef } from 'react';

// Prop types
import PropTypes from 'prop-types';

// DnD
import { useDrag, useDrop } from 'react-dnd';

// Constants
import { RED_MAIN, WHITE_A10, WHITE_MAIN } from 'constants/color';
import { DRAFT_BLUE_FIELDS, DRAFT_RED_FIELDS } from 'constants/draft';

// Assets
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// Elements
import { Grid2 as Grid, Box, Typography, IconButton, TextField } from '@mui/material';
import DraftPreparationSide from 'components/molecules/DraftPreparationSide/DraftPreparationSide';

// Component
const DraftPreparation = ({ id, value, index, champions, setFieldValue, moveItem, findItem }) => {
  const dragRef = useRef(null);
  const previewRef = useRef(null);

  const originalIndex = findItem(id).index;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'draft',
      item: { id, originalIndex },
    }),
    [id, originalIndex, moveItem],
  );
  const [, drop] = useDrop(
    () => ({
      accept: 'draft',
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id);

          moveItem(draggedId, overIndex);
        }
      },
    }),
    [findItem, moveItem],
  );
  const opacity = isDragging ? 0 : 1;

  const handleDraftChange = (draftIndex, field, newValue) => {
    setFieldValue(`drafts[${draftIndex}].${field}`, newValue);
  };

  const removeDraft = (draftIndex) => {
    const updatedDrafts = [...value];
    updatedDrafts.splice(draftIndex, 1);

    setFieldValue('drafts', updatedDrafts);
  };

  drag(dragRef);
  drop(preview(previewRef));

  return (
    <Grid ref={previewRef} size={{ xs: 12, md: 6, lg: 4, xl: 3 }} style={{ opacity }}>
      <Grid container alignItems="center">
        <Grid size={{ xs: 9 }}>
          <TextField
            value={value[index].title}
            onChange={(e) => handleDraftChange(index, 'title', e.target.value)}
            fullWidth
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '0.875rem',
                fontWeight: 700,
                textAlign: 'center',
                backgroundColor: WHITE_A10,
                padding: '3px',
              },
              '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  border: 0,
                },
            }}
          />
          <Grid container>
            <DraftPreparationSide
              data={DRAFT_BLUE_FIELDS}
              index={index}
              value={value}
              champions={champions}
              handleDraftChange={handleDraftChange}
            />
            <DraftPreparationSide
              data={DRAFT_RED_FIELDS}
              index={index}
              value={value}
              champions={champions}
              handleDraftChange={handleDraftChange}
              invert
            />
          </Grid>
        </Grid>
        <Grid size={{ xs: 3 }}>
          <IconButton ref={dragRef} size="small">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 64,
                height: 64,
              }}
            >
              <DragIndicatorIcon fontSize="large" />
            </Box>
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleDraftChange(index, 'staff_dislikes', !value[index].staff_dislikes)}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 64,
                height: 64,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: value[index].staff_dislikes ? RED_MAIN : WHITE_MAIN,
                  mb: 1 / 2,
                }}
              >
                Staff
              </Typography>
              <ThumbDownIcon
                fontSize="small"
                sx={{ color: value[index].staff_dislikes ? RED_MAIN : WHITE_MAIN }}
              />
            </Box>
          </IconButton>
          <IconButton
            size="small"
            onClick={() =>
              handleDraftChange(index, 'players_dislikes', !value[index].players_dislikes)
            }
            sx={{
              mt: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 64,
                height: 64,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: value[index].players_dislikes ? RED_MAIN : WHITE_MAIN,
                  mb: 1 / 2,
                }}
              >
                Players
              </Typography>
              <ThumbDownIcon
                fontSize="small"
                sx={{ color: value[index].players_dislikes ? RED_MAIN : WHITE_MAIN }}
              />
            </Box>
          </IconButton>
          <IconButton
            size="small"
            onClick={() => removeDraft(index)}
            sx={{
              mt: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 64,
                height: 64,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  mb: 1 / 2,
                }}
              >
                Remove
              </Typography>
              <DeleteIcon fontSize="small" />
            </Box>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

DraftPreparation.propTypes = {
  id: PropTypes.any.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  champions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired,
  findItem: PropTypes.func.isRequired,
};

export default DraftPreparation;
