// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Router
import { Link } from 'react-router-dom';

// Elements
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

// Component
const ToolbarActions = ({ buttons }) => {
  if (!buttons || buttons.length === 0) {
    return null;
  }

  return (
    <Box display="flex" gap={1} p={0.5}>
      {buttons.map(({ id, loadingState, loadingText, btnText, btnLink, btnIcon, btnFn }, index) => {
        const ml = index > 0 ? 1 : 0;

        if (loadingState !== undefined && loadingText !== undefined) {
          return (
            <LoadingButton
              key={id}
              variant="contained"
              type="submit"
              loading={loadingState}
              loadingIndicator={loadingText}
              startIcon={btnIcon}
              onClick={btnFn}
              sx={{ ml }}
            >
              {btnText}
            </LoadingButton>
          );
        }

        return (
          <Button
            key={id}
            variant="contained"
            component={Link}
            to={btnLink}
            startIcon={btnIcon}
            sx={{ ml }}
          >
            {btnText}
          </Button>
        );
      })}
    </Box>
  );
};

ToolbarActions.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ToolbarActions;
