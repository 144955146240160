// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchLvL1 } from 'services/data';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import { Grid2 as Grid } from '@mui/material';
import GameMap from 'components/molecules/GameMap/GameMap';

const TeamSingleLvL1Positions = () => {
  const {
    setTitleSEO,
    filters: {
      team: { matchType, rangeDate, side, patch, patches, opponent, opponents, currentTime, blitz },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    currentTimeData,
    teamId,
    startDateData,
    endDateData,
    patchData,
    opponentData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchLvL1(
      itemType,
      sideData,
      currentTimeData,
      teamId,
      startDateData,
      endDateData,
      patchData,
      opponentData,
      blitzData,
    ).then((res) => {
      setData(res.data);

      setFilters((prevValue) => ({
        ...prevValue,
        team: { ...prevValue.team, patches: res.patches, opponents: res.opponents },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        matchType,
        side,
        currentTime,
        team.id,
        rangeDate[0],
        rangeDate[1],
        patch,
        opponent,
        blitz,
      );
    }
  }, [matchType, rangeDate, side, currentTime, team, patch, opponent, blitz]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} LvL1 Positions`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            patchesFilter={patches}
            opponentsFilter={opponents}
            currentTimeFilter
            blitzFilter
            type="team"
          />
          <Grid container>
            {data &&
              data.map(({ id, date, side: gameSide, opponent: gameOpponent, win, positions }) => (
                <Grid key={id} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <GameMap
                    date={date}
                    opponent={gameOpponent}
                    win={win}
                    picks={positions}
                    side={gameSide}
                    time={currentTime}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      }
    />
  );
};

export default TeamSingleLvL1Positions;
