// React
import React, { useContext, useState, useMemo } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Context
import { Context } from 'context';

// Notistack
import { useSnackbar } from 'notistack';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Constants
import { BASIC_TABLE } from 'constants/mrt';
import { ROW_ACTIONS_PROPS, ROW_ACTIONS_WITH_PASSWORD_PROPS } from 'constants/tableHead';

// Elements
import { Typography } from '@mui/material';
import ToolbarActions from 'components/atoms/ToolbarActions/ToolbarActions';
import RowActions from 'components/atoms/RowActions/RowActions';
import DialogConfirm from 'components/atoms/DialogConfirm/DialogConfirm';

// Component
const AdminList = ({
  buttons = [],
  title = '',
  tableCols,
  tableData = [],
  field = 'name',
  baseURL = null,
  passwordBtn = false,
  passwordLink = null,
  editBtn = true,
  deleteBtn = true,
  deleteFn = null,
  refreshFn = null,
}) => {
  const { isDialogOpen, setIsDialogOpen } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const [currentRow, setCurrentRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const columns = useMemo(() => tableCols, [tableCols]);
  const table = useMaterialReactTable({
    columns,
    data: tableData,
    ...BASIC_TABLE,
    enableTableFooter: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <RowActions
        row={row}
        passwordBtn={passwordBtn}
        passwordLink={`${passwordLink}/${row.original.id}`}
        editBtn={editBtn}
        deleteBtn={deleteBtn}
        baseURL={baseURL}
        setRowFn={setCurrentRow}
      />
    ),
    displayColumnDefOptions: {
      'mrt-row-actions': passwordBtn ? ROW_ACTIONS_WITH_PASSWORD_PROPS : ROW_ACTIONS_PROPS,
    },
    renderTopToolbarCustomActions: () =>
      buttons && buttons.length > 0 ? <ToolbarActions buttons={buttons} /> : null,
  });

  const handleDelete = () => {
    setIsLoading(true);

    deleteFn(currentRow.original.id).then((res) => {
      const variantSnackbar = res.error ? 'error' : 'success';

      enqueueSnackbar(res.message, {
        variant: variantSnackbar,
      });

      refreshFn();
      setIsDialogOpen(false);
      setIsLoading(false);
    });
  };

  return (
    <>
      <Typography variant="h5" component="h1" gutterBottom>
        {title}
      </Typography>
      <MaterialReactTable table={table} />
      {deleteBtn && deleteFn && refreshFn && currentRow ? (
        <DialogConfirm
          open={isDialogOpen}
          itemName={currentRow.original[field]}
          dialogFn={handleDelete}
          loading={isLoading}
        />
      ) : null}
    </>
  );
};

AdminList.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  tableCols: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.any),
  field: PropTypes.string,
  passwordBtn: PropTypes.bool,
  passwordLink: PropTypes.string,
  editBtn: PropTypes.bool,
  deleteBtn: PropTypes.bool,
  baseURL: PropTypes.string,
  deleteFn: PropTypes.func,
  refreshFn: PropTypes.func,
};

export default AdminList;
