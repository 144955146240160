// React
import React, { useCallback } from 'react';

// Prop types
import PropTypes from 'prop-types';

// DnD
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';

// Elements
import { Grid2 as Grid } from '@mui/material';
import DraftPreparation from 'components/molecules/DraftPreparation/DraftPreparation';

// Component
const DraftsPreparationContainer = ({ value, champions, setFieldValue }) => {
  const findItem = useCallback(
    (id) => {
      const item = value.filter((c) => c.key === id)[0];

      return {
        item,
        index: value.indexOf(item),
      };
    },
    [value],
  );
  const moveItem = useCallback(
    (id, atIndex) => {
      const { item, index } = findItem(id);

      setFieldValue(
        'drafts',
        update(value, {
          $splice: [
            [index, 1],
            [atIndex, 0, item],
          ],
        }),
      );
    },
    [value],
  );
  const [, drop] = useDrop(() => ({ accept: 'item' }));

  return (
    <Grid container ref={drop}>
      {value.map(({ key: keyDraft }, draftIndex) => (
        <DraftPreparation
          key={keyDraft}
          id={keyDraft}
          value={value}
          index={draftIndex}
          champions={champions}
          setFieldValue={setFieldValue}
          moveItem={moveItem}
          findItem={findItem}
        />
      ))}
    </Grid>
  );
};

DraftsPreparationContainer.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  champions: PropTypes.arrayOf(PropTypes.object),
  setFieldValue: PropTypes.func.isRequired,
};

export default DraftsPreparationContainer;
