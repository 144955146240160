// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Router
import { useNavigate } from 'react-router-dom';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Routes
import { performanceBasicURL } from 'routes/app';

// Services
import { fetchPerformanceReport } from 'services/performance';

// Constants
import { HIDE_TOP_TOOLBAR, HIDE_PAGINATION, DETAIL_PANEL_TABLE } from 'constants/mrt';
import {
  PERFORMANCE_REPORT_ATE_COLS,
  PERFORMANCE_REPORT_ATE_DETAIL_COLS,
  PERFORMANCE_REPORT_SPORT_COLS,
  PERFORMANCE_REPORT_SPORT_DETAIL_COLS,
  PERFORMANCE_REPORT_SNACK_COLS,
  PERFORMANCE_REPORT_SNACK_DETAIL_COLS,
} from 'constants/tableHead';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid2 as Grid, Typography, Chip } from '@mui/material';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import GameTable from 'components/atoms/GameTable/GameTable';

const PerformanceReportView = () => {
  const navigate = useNavigate();
  const {
    setTitleSEO,
    filters: {
      performance: { rangeDate },
    },
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Performance Report';

  const handleClick = () => {
    navigate(performanceBasicURL);
  };

  const fetchData = (startDateData, endDateData) => {
    setIsLoading(true);

    fetchPerformanceReport(startDateData, endDateData).then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(rangeDate[0], rangeDate[1]);
    }
  }, [rangeDate]);

  const columnsAte = useMemo(() => PERFORMANCE_REPORT_ATE_COLS, []);
  const tableAte = useMaterialReactTable({
    columns: columnsAte,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
    ...DETAIL_PANEL_TABLE,
    renderDetailPanel: ({
      row: {
        original: { data: detailData },
      },
    }) => (
      <GameTable
        data={detailData}
        columns={useMemo(() => PERFORMANCE_REPORT_ATE_DETAIL_COLS, [])}
      />
    ),
    initialState: {
      columnPinning: { left: ['name', 'mrt-row-expand'] },
    },
  });

  const columnsSport = useMemo(() => PERFORMANCE_REPORT_SPORT_COLS, []);
  const tableSport = useMaterialReactTable({
    columns: columnsSport,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
    ...DETAIL_PANEL_TABLE,
    renderDetailPanel: ({
      row: {
        original: { data: detailData },
      },
    }) => (
      <GameTable
        data={detailData}
        columns={useMemo(() => PERFORMANCE_REPORT_SPORT_DETAIL_COLS, [])}
      />
    ),
    initialState: {
      columnPinning: { left: ['name', 'mrt-row-expand'] },
    },
  });

  const columnsSnack = useMemo(() => PERFORMANCE_REPORT_SNACK_COLS, []);
  const tableSnack = useMaterialReactTable({
    columns: columnsSnack,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
    ...DETAIL_PANEL_TABLE,
    renderDetailPanel: ({
      row: {
        original: { data: detailData },
      },
    }) => (
      <GameTable
        data={detailData}
        columns={useMemo(() => PERFORMANCE_REPORT_SNACK_DETAIL_COLS, [])}
      />
    ),
    initialState: {
      columnPinning: { left: ['name', 'mrt-row-expand'] },
    },
  });

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <PageTitle title={title} disableGutterBottom />
        <Chip
          label="DATA"
          size="small"
          onClick={handleClick}
          sx={{
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 700,
            ml: 2,
            pl: 0.5,
            pr: 0.5,
          }}
        />
      </Box>
      <DataFilters rangeDateFilter type="performance" />
      <Grid container>
        <Grid size={{ xs: 12, lg: 6, xl: 4 }}>
          <Typography variant="h6" gutterBottom>
            Ate
          </Typography>
          <MaterialReactTable table={tableAte} />
        </Grid>
        <Grid size={{ xs: 12, lg: 6, xl: 4 }}>
          <Typography variant="h6" gutterBottom>
            Sport
          </Typography>
          <MaterialReactTable table={tableSport} />
        </Grid>
        <Grid size={{ xs: 12, lg: 6, xl: 4 }}>
          <Typography variant="h6" gutterBottom>
            Snack
          </Typography>
          <MaterialReactTable table={tableSnack} />
        </Grid>
      </Grid>
    </>
  );
};

export default PerformanceReportView;
