// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// DnD
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Utils
import { generateUniqueKey } from 'utils/key';

// Constants
import { DRAFT_FIELDS } from 'constants/draft';

// Assets
import AddIcon from '@mui/icons-material/Add';

// Elements
import { Box, Typography, Button } from '@mui/material';
import DraftsPreparationContainer from 'components/organisms/DraftsPreparationContainer/DraftsPreparationContainer';

// Component
const DraftsManageData = ({
  value,
  loadData = {
    champions: [],
  },
  setFieldValue,
}) => {
  const addNewDraft = () => {
    const newDraftFields = DRAFT_FIELDS.reduce((acc, field) => {
      acc[field] = null;
      acc[`${field}_id`] = '';

      if (field[1] === 'p') {
        acc[`${field}_role`] = '';
      }

      return acc;
    }, {});

    const newItem = {
      key: generateUniqueKey(),
      title: '',
      order: 0,
      staff_dislikes: false,
      players_dislikes: false,
      ...newDraftFields,
    };

    setFieldValue('drafts', [newItem, ...value]);
  };

  return (
    <Box>
      <Typography variant="subtitle1">Drafts</Typography>
      {loadData.champions.length > 0 ? (
        <Button variant="text" size="small" startIcon={<AddIcon />} onClick={addNewDraft}>
          Add draft
        </Button>
      ) : null}
      <DndProvider backend={HTML5Backend}>
        <DraftsPreparationContainer
          value={value}
          champions={loadData.champions}
          setFieldValue={setFieldValue}
        />
      </DndProvider>
    </Box>
  );
};

DraftsManageData.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadData: PropTypes.shape({
    champions: PropTypes.arrayOf(PropTypes.object),
  }),
  setFieldValue: PropTypes.func.isRequired,
};

export default DraftsManageData;
