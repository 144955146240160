// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { preparationBasicURL } from 'routes/app';

// Services
import { addPreparation } from 'services/preparation';
import { fetchTeams } from 'services/teams';
import { fetchChampions } from 'services/champions';

// Constants
import { MATCH_TYPES_LIST_BASIC } from 'constants/match';

// Schemas
import { BOOLEAN_SCHEMA, STRING_REQUIRED_SCHEMA, OBJECT_REQUIRED_SCHEMA } from 'schemas/base';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const PreparationAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [teamOptions, setTeamOptions] = useState([]);
  const [championOptions, setChampionOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchChampions().then((res) => setChampionOptions(res));

    fetchTeams().then((res) => {
      setTeamOptions(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(false);

    if (!renderAfterCalled.current) {
      fetchData();

      setTitleSEO('Add Preparation');
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    type: '',
    enemy_team: null,
    is_red_side: false,
    prio_picks: [],
    worth_bans: [],
    drafts: [],
  };
  const validationSchemaDict = {
    type: STRING_REQUIRED_SCHEMA,
    enemy_team: OBJECT_REQUIRED_SCHEMA,
    is_red_side: BOOLEAN_SCHEMA,
    prio_picks: Yup.array(),
    worth_bans: Yup.array(),
    drafts: Yup.array(),
  };

  const validationSchema = Yup.object().shape(validationSchemaDict);

  const fields1 = [
    {
      id: 'type',
      label: 'Type',
      typeField: 'select',
      options: MATCH_TYPES_LIST_BASIC,
      optionLabel: 'label',
    },
    {
      id: 'enemy_team',
      label: 'Enemy Team',
      typeField: 'autocomplete',
      options: teamOptions,
    },
    {
      id: 'is_red_side',
      type: 'side',
      typeField: 'switch',
    },
  ];

  const fields2 = [
    {
      id: 'prio_picks',
      label: 'Prio picks',
      typeField: 'multiautocomplete',
      options: championOptions,
    },
    {
      id: 'worth_bans',
      label: 'Worth bans',
      typeField: 'multiautocomplete',
      options: championOptions,
    },
  ];

  const fields3 = [];

  const fields4 = [
    {
      id: 'drafts',
      label: 'Drafts',
      typeField: 'drafts',
      loadData: {
        champions: championOptions,
      },
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields3={fields3}
      formikFields3Size={2}
      formikFields4={fields4}
      formikFields4Size={4}
      formikFn={addPreparation}
      formikRedirect={preparationBasicURL}
      formikBtnText="Add preparation"
    />
  );
};

export default PreparationAddView;
