// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { libraryBasicURL } from 'routes/app';

// Services
import { addLibrary } from 'services/library';
import { fetchTags } from 'services/tags';

// Schemas
import { STRING_SCHEMA, STRING_REQUIRED_SCHEMA, BOOLEAN_SCHEMA } from 'schemas/base';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const LibraryAddView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [options, setOptions] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchTags('VOD').then((res) => {
      setOptions(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(false);

    if (!renderAfterCalled.current) {
      fetchData();

      setTitleSEO('Add Library Link');
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    game_id: '',
    is_red_side: false,
    url: '',
    game_time: '',
    tags: [],
    note: '',
  };
  const validationSchemaDict = {
    game_id: STRING_REQUIRED_SCHEMA,
    is_red_side: BOOLEAN_SCHEMA,
    url: Yup.string().url().required('Required'),
    game_time: STRING_REQUIRED_SCHEMA,
    note: STRING_SCHEMA,
  };

  const validationSchema = Yup.object().shape(validationSchemaDict);

  const fields1 = [
    {
      id: 'url',
      label: 'URL',
      typeField: 'textfield',
    },
    {
      id: 'game_time',
      label: 'Game Time',
      typeField: 'textfield',
    },
  ];

  const fields2 = [
    {
      id: 'game_id',
      typeField: 'game',
    },
    {
      id: 'is_red_side',
      type: 'side',
      typeField: 'switch',
    },
  ];

  const fields3 = [
    {
      id: 'tags',
      label: 'Tags',
      typeField: 'multiautocomplete',
      options,
      disabled: false,
      freeSolo: true,
      newItemType: 'VOD',
    },
    {
      id: 'note',
      label: 'Note',
      typeField: 'textfield',
      rows: 5,
      multiline: true,
    },
  ];

  return (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="add"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields3={fields3}
      formikFn={addLibrary}
      formikRedirect={libraryBasicURL}
      formikBtnText="Add link"
    />
  );
};

export default LibraryAddView;
