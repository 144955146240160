export const adminBaseURL = '/admin';

export const adminHomeURL = adminBaseURL;

export const adminOptionsURL = `${adminBaseURL}/options`;
export const adminOptionsAddURL = `${adminBaseURL}/options/add`;
export const adminOptionsEditURL = `${adminBaseURL}/options/edit/:id`;
export const adminOptionsBaseURL = `${adminBaseURL}/options/edit`;

export const adminUsersURL = `${adminBaseURL}/users`;
export const adminUsersAddURL = `${adminBaseURL}/users/add`;
export const adminUsersEditURL = `${adminBaseURL}/users/edit/:id`;
export const adminUsersBaseURL = `${adminBaseURL}/users/edit`;
export const adminUsersPasswordURL = `${adminBaseURL}/users/password/:id`;
export const adminUsersPasswordBaseURL = `${adminBaseURL}/users/password`;

export const adminTagsURL = `${adminBaseURL}/tags`;
export const adminTagsAddURL = `${adminBaseURL}/tags/add`;
export const adminTagsEditURL = `${adminBaseURL}/tags/edit/:id`;
export const adminTagsBaseURL = `${adminBaseURL}/tags/edit`;

export const adminChampionsURL = `${adminBaseURL}/champions`;
export const adminChampionsAddURL = `${adminBaseURL}/champions/add`;
export const adminChampionsEditURL = `${adminBaseURL}/champions/edit/:id`;
export const adminChampionsBaseURL = `${adminBaseURL}/champions/edit`;

export const adminChampionSpellsURL = `${adminBaseURL}/champions-spells`;

export const adminItemsURL = `${adminBaseURL}/items`;
export const adminItemsAddURL = `${adminBaseURL}/items/add`;
export const adminItemsEditURL = `${adminBaseURL}/items/edit/:id`;
export const adminItemsBaseURL = `${adminBaseURL}/items/edit`;

export const adminPatchesURL = `${adminBaseURL}/patches`;
export const adminPatchesAddURL = `${adminBaseURL}/patches/add`;
export const adminPatchesEditURL = `${adminBaseURL}/patches/edit/:id`;
export const adminPatchesBaseURL = `${adminBaseURL}/patches/edit`;

export const adminSummonerSpellsURL = `${adminBaseURL}/summoner-spells`;

export const adminRunesURL = `${adminBaseURL}/runes`;

export const adminKeystonesURL = `${adminBaseURL}/keystones`;

export const adminPerksURL = `${adminBaseURL}/perks`;

export const adminShardsURL = `${adminBaseURL}/shards`;

export const adminRegionsURL = `${adminBaseURL}/regions`;
export const adminRegionsAddURL = `${adminBaseURL}/regions/add`;
export const adminRegionsEditURL = `${adminBaseURL}/regions/edit/:id`;
export const adminRegionsBaseURL = `${adminBaseURL}/regions/edit`;

export const adminServersURL = `${adminBaseURL}/servers`;
export const adminServersAddURL = `${adminBaseURL}/servers/add`;
export const adminServersEditURL = `${adminBaseURL}/servers/edit/:id`;
export const adminServersBaseURL = `${adminBaseURL}/servers/edit`;

export const adminTiersURL = `${adminBaseURL}/tiers`;
export const adminTiersAddURL = `${adminBaseURL}/tiers/add`;
export const adminTiersEditURL = `${adminBaseURL}/tiers/edit/:id`;
export const adminTiersBaseURL = `${adminBaseURL}/tiers/edit`;

export const adminAccountsURL = `${adminBaseURL}/accounts`;
export const adminAccountsAddURL = `${adminBaseURL}/accounts/add`;
export const adminAccountsEditURL = `${adminBaseURL}/accounts/edit/:id`;
export const adminAccountsBaseURL = `${adminBaseURL}/accounts/edit`;

export const adminRankedGamesURL = `${adminBaseURL}/ranked-games`;

export const adminNationalitiesURL = `${adminBaseURL}/nationalities`;
export const adminNationalitiesAddURL = `${adminBaseURL}/nationalities/add`;
export const adminNationalitiesEditURL = `${adminBaseURL}/nationalities/edit/:id`;
export const adminNationalitiesBaseURL = `${adminBaseURL}/nationalities/edit`;

export const adminLeaguesURL = `${adminBaseURL}/leagues`;
export const adminLeaguesAddURL = `${adminBaseURL}/leagues/add`;
export const adminLeaguesEditURL = `${adminBaseURL}/leagues/edit/:id`;
export const adminLeaguesBaseURL = `${adminBaseURL}/leagues/edit`;

export const adminSplitsURL = `${adminBaseURL}/splits`;
export const adminSplitsAddURL = `${adminBaseURL}/splits/add`;
export const adminSplitsEditURL = `${adminBaseURL}/splits/edit/:id`;
export const adminSplitsBaseURL = `${adminBaseURL}/splits/edit`;

export const adminTeamsURL = `${adminBaseURL}/teams`;
export const adminTeamsAddURL = `${adminBaseURL}/teams/add`;
export const adminTeamsEditURL = `${adminBaseURL}/teams/edit/:id`;
export const adminTeamsBaseURL = `${adminBaseURL}/teams/edit`;

export const adminPlayersURL = `${adminBaseURL}/players`;
export const adminPlayersAddURL = `${adminBaseURL}/players/add`;
export const adminPlayersEditURL = `${adminBaseURL}/players/edit/:id`;
export const adminPlayersBaseURL = `${adminBaseURL}/players/edit`;

export const adminPlayerUrnsURL = `${adminBaseURL}/player-urns`;
export const adminPlayerUrnsAddURL = `${adminBaseURL}/player-urns/add`;
export const adminPlayerUrnsEditURL = `${adminBaseURL}/player-urns/edit/:id`;
export const adminPlayerUrnsBaseURL = `${adminBaseURL}/player-urns/edit`;

export const adminMatchesURL = `${adminBaseURL}/matches`;
export const adminMatchesAddURL = `${adminBaseURL}/matches/add`;
export const adminMatchesEditURL = `${adminBaseURL}/matches/edit/:id`;
export const adminMatchesBaseURL = `${adminBaseURL}/matches/edit`;

export const adminGamesURL = `${adminBaseURL}/games`;
export const adminGamesEditURL = `${adminBaseURL}/games/edit/:id`;
export const adminGamesBaseURL = `${adminBaseURL}/games/edit`;

export const adminChampionsQueuesURL = `${adminBaseURL}/champions-queues`;
export const adminChampionsQueuesAddURL = `${adminBaseURL}/champions-queues/add`;
export const adminChampionsQueuesEditURL = `${adminBaseURL}/champions-queues/edit/:id`;
export const adminChampionsQueuesBaseURL = `${adminBaseURL}/champions-queues/edit`;

export const adminChampionsQueueGamesURL = `${adminBaseURL}/champions-queue-games`;

export const adminUnknownUrnsURL = `${adminBaseURL}/unknown-urns`;
export const adminUnknownUrnsEditURL = `${adminBaseURL}/unknown-urns/edit/:id`;
export const adminUnknownUrnsBaseURL = `${adminBaseURL}/unknown-urns/edit`;

export const adminGameErrorsURL = `${adminBaseURL}/game-errors`;

export const adminChampionsQueueGameErrorsURL = `${adminBaseURL}/champions-queue-game-errors`;
