// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Elements
import { Typography } from '@mui/material';

// Component
const CardText = ({ text, mr = 0 }) => (
  <Typography variant="body2" component="p" fontWeight="bold" mr={mr}>
    {text}
  </Typography>
);

CardText.propTypes = {
  text: PropTypes.string.isRequired,
  mr: PropTypes.number,
};

export default CardText;
