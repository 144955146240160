// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Routes
import { preparationBasicURL } from 'routes/app';

// Services
import { fetchSinglePreparation, updatePreparation } from 'services/preparation';
import { fetchTeams } from 'services/teams';
import { fetchChampions } from 'services/champions';

// Constants
import { MATCH_TYPES_LIST_BASIC } from 'constants/match';

// Schemas
import { BOOLEAN_SCHEMA, STRING_REQUIRED_SCHEMA, OBJECT_REQUIRED_SCHEMA } from 'schemas/base';

// Elements
import AdminManage from 'components/templates/AdminManage/AdminManage';

const PreparationEditView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);
  const [teamSide, setTeamSide] = useState(false);
  const [championOptions, setChampionOptions] = useState([]);
  const [currentPrioPicks, setCurrentPrioPicks] = useState([]);
  const [currentWorthBans, setCurrentWorthBans] = useState([]);
  const [currentDrafts, setCurrentDrafts] = useState([]);
  const renderAfterCalled = useRef(false);

  const fetchData = () => {
    setIsLoading(true);

    fetchChampions().then((res) => setChampionOptions(res));

    fetchTeams().then((res) => setTeamOptions(res));

    fetchSinglePreparation(id).then((res) => {
      setData(res);

      setTeamSide(res?.side === 'RED');

      const prioPicks = res?.prio_picks.map((pp) => pp.champion);

      setCurrentPrioPicks(prioPicks);

      const worthBans = res?.worth_bans.map((wb) => wb.champion);

      setCurrentWorthBans(worthBans);

      const drafts = [];

      res?.drafts.forEach((draft) => {
        const singleDraft = {
          key: draft.id,
          title: draft.title,
          order: draft.order,
          staff_dislikes: draft.staff_dislikes,
          players_dislikes: draft.players_dislikes,
        };

        draft?.bans.forEach((ban) => {
          const key = `${ban.side[0].toLowerCase()}b${ban.order}`;

          singleDraft[key] = ban.champion;
          singleDraft[`${key}_id`] = ban.champion_id;
        });

        draft?.picks.forEach((pick) => {
          const key = `${pick.side[0].toLowerCase()}p${pick.order}`;

          singleDraft[key] = pick.champion;
          singleDraft[`${key}_id`] = pick.champion_id;
          singleDraft[`${key}_role`] = pick.role ? pick.role[0].toUpperCase() : '';
        });

        drafts.push(singleDraft);
      });

      setCurrentDrafts(drafts);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (id && !renderAfterCalled.current) {
      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setTitleSEO('Edit Preparation');
    }
  }, [data]);

  const initialValues = {
    type: data?.type.toLowerCase() || '',
    enemy_team: teamOptions.find((option) => option.id === data?.enemy_team_id) || null,
    is_red_side: teamSide,
    prio_picks: currentPrioPicks || [],
    worth_bans: currentWorthBans || [],
    drafts: currentDrafts || [],
  };
  const validationSchemaDict = {
    type: STRING_REQUIRED_SCHEMA,
    enemy_team: OBJECT_REQUIRED_SCHEMA,
    is_red_side: BOOLEAN_SCHEMA,
    prio_picks: Yup.array(),
    worth_bans: Yup.array(),
    drafts: Yup.array(),
  };

  const validationSchema = Yup.object().shape(validationSchemaDict);

  const fields1 = [
    {
      id: 'type',
      label: 'Type',
      typeField: 'select',
      options: MATCH_TYPES_LIST_BASIC,
      optionLabel: 'label',
    },
    {
      id: 'enemy_team',
      label: 'Enemy Team',
      typeField: 'autocomplete',
      options: teamOptions,
    },
    {
      id: 'is_red_side',
      type: 'side',
      typeField: 'switch',
    },
  ];

  const fields2 = [
    {
      id: 'prio_picks',
      label: 'Prio picks',
      typeField: 'multiautocomplete',
      options: championOptions,
    },
    {
      id: 'worth_bans',
      label: 'Worth bans',
      typeField: 'multiautocomplete',
      options: championOptions,
    },
  ];

  const fields3 = [];

  const fields4 = [
    {
      id: 'drafts',
      label: 'Drafts',
      typeField: 'drafts',
      loadData: {
        champions: championOptions,
      },
    },
  ];

  return data && Object.keys(data).length > 0 ? (
    <AdminManage
      initialValues={initialValues}
      validationSchema={validationSchema}
      formikType="edit"
      formikFields1={fields1}
      formikFields2={fields2}
      formikFields3={fields3}
      formikFields3Size={2}
      formikFields4={fields4}
      formikFields4Size={4}
      formikFn={updatePreparation}
      formikAdditionalFnArgs={[id]}
      formikRedirect={preparationBasicURL}
      formikBtnText="Update preparation"
    />
  ) : null;
};

export default PreparationEditView;
