// React
import React, { useContext } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Context
import { Context } from 'context';

// Constants
import { BLUE_MAIN, GREEN_MAIN, RED_MAIN } from 'constants/color';

// Assets
import CircleIcon from '@mui/icons-material/Circle';

// Elements
import { Grid2 as Grid, Box, Typography } from '@mui/material';

// Component
const PerformanceTableData = ({ cell = null }) => {
  const { setIsModalTableOpen, setModalTableData } = useContext(Context);

  const fontSize = 14;
  const valueData = cell !== null ? cell.getValue() : null;
  const dataId = cell?.id;
  const isTeamFights = dataId.includes('team_fights');

  if (valueData === null || valueData === undefined || valueData === '') {
    return null;
  }

  let countB = 0;
  let countN = 0;
  let countG = 0;

  valueData.forEach((item) => {
    if (item.rate === 'G') {
      countG += 1;
    } else if (item.rate === 'N') {
      countN += 1;
    } else if (item.rate === 'B') {
      countB += 1;
    }
  });

  const openModal = () => {
    setModalTableData({
      data: valueData,
      type: dataId,
    });
    setIsModalTableOpen(true);
  };

  return (
    <Box onClick={openModal} sx={{ width: '100%', cursor: 'pointer' }}>
      <Grid container>
        <Grid size={{ xs: 12, md: isTeamFights ? 6 : 4 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircleIcon sx={{ fontSize, color: RED_MAIN }} />
            <Typography variant="body2" component="span" mt={1 / 4} fontSize={fontSize}>
              {countB}
            </Typography>
          </Box>
        </Grid>
        {!isTeamFights ? (
          <Grid size={{ xs: 12, md: 4 }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircleIcon sx={{ fontSize, color: BLUE_MAIN }} />
              <Typography variant="body2" component="span" mt={1 / 4} fontSize={fontSize}>
                {countN}
              </Typography>
            </Box>
          </Grid>
        ) : null}
        <Grid size={{ xs: 12, md: isTeamFights ? 6 : 4 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircleIcon sx={{ fontSize, color: GREEN_MAIN }} />
            <Typography variant="body2" component="span" mt={1 / 4} fontSize={fontSize}>
              {countG}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

PerformanceTableData.propTypes = {
  cell: PropTypes.object,
};

export default PerformanceTableData;
