// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Constants
import { RED_MAIN } from 'constants/color';

// Elements
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select as SelectMUI,
  FormHelperText,
} from '@mui/material';

// Component
const Select = ({
  name = '',
  label = '',
  optionLabel = 'name',
  options,
  displayEmpty = true,
  value = '',
  compareValue = 'id',
  onClick = null,
  onChange,
  error = false,
  helperText = '',
  margin = 'dense',
  minWidth = 150,
  disabled = false,
}) => (
  <Box sx={{ minWidth }}>
    <FormControl margin={margin} fullWidth>
      <InputLabel>{label}</InputLabel>
      <SelectMUI
        id={name}
        name={name}
        label={label}
        value={value}
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        error={error}
      >
        {displayEmpty ? <MenuItem value="">None</MenuItem> : null}
        {Object.keys(options).length > 0
          ? options.map((el) => (
              <MenuItem key={el.id} value={el[compareValue]}>
                {el[optionLabel]}
              </MenuItem>
            ))
          : null}
      </SelectMUI>
      {helperText ? <FormHelperText sx={{ color: RED_MAIN }}>{helperText}</FormHelperText> : null}
    </FormControl>
  </Box>
);

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  optionLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  displayEmpty: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  compareValue: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  margin: PropTypes.string,
  minWidth: PropTypes.number,
  disabled: PropTypes.bool,
};

export default Select;
