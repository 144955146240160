// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Box, Grid2 as Grid, Card, CardContent, Typography } from '@mui/material';
import GamePercentageData from 'components/atoms/GamePercentageData/GamePercentageData';
import CardTitle from 'components/atoms/CardTitle/CardTitle';
import ChampionsCard from 'components/molecules/ChampionsCard/ChampionsCard';
import PlayerStatisticCard from 'components/molecules/PlayerStatisticCard/PlayerStatisticCard';

// Component
const PlayerData = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return null;
  }

  const { games, picks, statistics } = data;

  const renderGames = () =>
    Object.keys(games).map((key) => {
      const { count, win_rate } = games[key];

      return (
        <Typography key={key} variant="body2" component="p" textAlign="center">
          {key}: {count} (<GamePercentageData value={win_rate} fixNumbers />)
        </Typography>
      );
    });

  const renderPicks = () =>
    Object.keys(picks).map((key) => (
      <Grid key={key} size={{ xs: 6, sm: 12, md: 6, lg: 3, xl: 2 }}>
        <ChampionsCard title={key} data={picks[key]} />
      </Grid>
    ));

  const renderStatistics = () =>
    Object.keys(statistics).map((key) => (
      <Grid key={key} size={{ xs: 6, sm: 12, md: 6, lg: 3, xl: 2 }}>
        <PlayerStatisticCard title={key} data={statistics[key]} />
      </Grid>
    ));

  return (
    <Grid container>
      <Grid size={{ xs: 6, sm: 12, md: 6, lg: 3, xl: 2 }}>
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <CardTitle text="Games" />
            <Box display="flex" flexDirection="column" gap={1} mt={3}>
              {renderGames()}
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {renderPicks()}
      {renderStatistics()}
    </Grid>
  );
};

PlayerData.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PlayerData;
