// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Placeholder
import placeholder from 'assets/img/placeholder.png';

// Elements
import { Box } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

// Component
const ImageTitle = ({ image = null, name, title }) => (
  <Box display="flex" alignItems="center" mb={1}>
    <ImageItem src={image || placeholder} alt={name} />
    <PageTitle title={title} ml={1} disableGutterBottom />
  </Box>
);

ImageTitle.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ImageTitle;
