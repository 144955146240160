// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import { Grid2 as Grid } from '@mui/material';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import Draft from 'components/organisms/Draft/Draft';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

// Component
const DraftsTemplate = ({
  data,
  title,
  team = null,
  patches = [],
  opponents = [],
  leaguepedia = false,
}) => (
  <ViewWithSidebar
    component={
      <>
        {team ? (
          <ImageTitle image={team?.image_url} name={team?.name} title={title} />
        ) : (
          <PageTitle title={title} />
        )}
        <DataFilters
          matchTypeFilter={!!team && !leaguepedia}
          rangeDateFilter
          sideFilter
          resultFilter={!leaguepedia}
          patchesFilter={patches}
          opponentsFilter={opponents}
          blitzFilter
          draftFilter
          type={team ? 'team' : 'scrims'}
        />
        {data && data.length > 0 && (
          <Grid container>
            {data.map((el) => (
              <Grid key={el.id} size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 12 / 7 }}>
                <Draft data={el} />
              </Grid>
            ))}
          </Grid>
        )}
      </>
    }
  />
);

DraftsTemplate.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  team: PropTypes.object,
  patches: PropTypes.array,
  opponents: PropTypes.array,
  leaguepedia: PropTypes.bool,
};

export default DraftsTemplate;
