// Axios
import { axiosInstance, axiosLoadingInstance } from 'utils/axios';

export const fetchChampionsQueueGameErrors = () =>
  axiosLoadingInstance
    .get('champions-queue-game-errors/')
    .then((res) => res.data)
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteChampionsQueueGameError = (id) =>
  axiosInstance
    .delete(`champions-queue-game-errors/${id}/`)
    .then((res) => ({
      data: res.data,
      message: 'Champions Queue Game error has been deleted!',
      error: 0,
    }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const deleteAllChampionsQueueGameError = () =>
  axiosInstance
    .delete(`champions-queue-game-errors/all/`)
    .then((res) => ({
      data: res.data,
      message: 'Champions Queue Games error have been deleted!',
      error: 0,
    }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
