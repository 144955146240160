// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { BLUE_MAIN, RED_MAIN } from 'constants/color';

// Assets
import CircleIcon from '@mui/icons-material/Circle';

// Component
const SideData = ({ cell = null, side = null }) => {
  const { getValue } = cell;
  const color = (cell && getValue() === 'BLUE') || side === 'BLUE' ? BLUE_MAIN : RED_MAIN;

  return <CircleIcon fontSize="small" sx={{ color }} />;
};

SideData.propTypes = {
  cell: PropTypes.object,
  side: PropTypes.string,
};

export default SideData;
