// React
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Utils
import { drawPositions } from 'utils/canvas';

// Constants
import { HIDE_TOP_TOOLBAR, HIDE_PAGINATION, ROW_NUMBERS } from 'constants/mrt';
import {
  ROW_ACTIONS_TEAM_FIGHTS_PROPS,
  GAME_TEAM_FIGHTS_COLS,
  GAME_TEAM_FIGHT_DATA_COLS,
} from 'constants/tableHead';
import { CANVAS_PROPORTION } from 'constants/canvas';

// Assets
import mapImage from 'assets/img/map.png';

// Elements
import { Button, Grid2 as Grid } from '@mui/material';

// Styles
import { StyledMap, StyledCanvas } from 'assets/styles/Map.styles';

// Component
const GameTeamFightsMap = ({ data = null }) => {
  const mapRef = useRef(null);
  const canvasRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (e) => {
    const { id } = e.target;

    if (id) {
      setCurrentIndex(parseInt(id, 10));
    }
  };

  const drawMap = useCallback(() => {
    const mapCanvas = canvasRef.current;

    if (mapCanvas) {
      const mapItem = mapCanvas.parentElement;
      const mapWidth = mapItem.clientWidth;
      const mapHeight = mapItem.clientHeight;

      mapCanvas.width = mapWidth;
      mapCanvas.height = mapHeight;

      const ctx = mapCanvas.getContext('2d');
      const scale = Math.min(mapWidth, mapHeight) / CANVAS_PROPORTION;

      if (data && data.length > 0) {
        const positionsData = data[currentIndex]?.data.map(
          ({ image_url: image, side, role, x, y, died }) => ({
            image,
            side,
            role,
            x,
            y,
            dead: died,
          }),
        );

        drawPositions(ctx, positionsData, mapWidth, mapHeight, scale);
      }
    }
  }, [currentIndex, data]);

  useEffect(() => {
    drawMap();

    const handleResize = () => {
      drawMap();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [drawMap]);

  const columnsTeamFights = useMemo(() => GAME_TEAM_FIGHTS_COLS, []);
  const tableTeamFights = useMaterialReactTable({
    columns: columnsTeamFights,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
    ...ROW_NUMBERS,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Button
        variant="contained"
        size="small"
        id={row.index}
        color={row.index === currentIndex ? 'primary' : 'error'}
        onClick={handleClick}
        sx={{
          width: '100%',
        }}
      >
        {row.index === currentIndex ? 'Current' : 'Show'}
      </Button>
    ),
    displayColumnDefOptions: {
      ...ROW_NUMBERS.displayColumnDefOptions,
      'mrt-row-actions': ROW_ACTIONS_TEAM_FIGHTS_PROPS,
    },
  });

  const columnsTeamFightData = useMemo(() => GAME_TEAM_FIGHT_DATA_COLS, []);
  const tableTeamFightData = useMaterialReactTable({
    columns: columnsTeamFightData,
    data: data[currentIndex].data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
  });

  return (
    <Grid container>
      <Grid size={{ xs: 12, md: 6, lg: 4 }} order={{ xs: 3, lg: 1 }}>
        <StyledMap image={mapImage} ref={mapRef}>
          <StyledCanvas ref={canvasRef} />
        </StyledMap>
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4, xl: 5 }} order={{ xs: 2 }}>
        <MaterialReactTable table={tableTeamFightData} />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 4, xl: 3 }} order={{ xs: 1, lg: 3 }}>
        <MaterialReactTable table={tableTeamFights} />
      </Grid>
    </Grid>
  );
};

GameTeamFightsMap.propTypes = {
  data: PropTypes.array,
};

export default GameTeamFightsMap;
