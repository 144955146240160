// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchChampions } from 'services/champions';
import { searchVods } from 'services/leaguepedia';

// Constants
import { SEARCH_TEAM_C1_TYPE, SEARCH_TEAM_TYPE } from 'constants/team';
import { HIDE_TOP_TOOLBAR } from 'constants/mrt';
import { SEARCH_VOD_COLS } from 'constants/tableHead';
import { LOADING_TEXT } from 'constants/text';
import { ROLES_ANY } from 'constants/role';

// Schemas
import {
  STRING_SCHEMA,
  BOOLEAN_SCHEMA,
  STRING_REQUIRED_SCHEMA,
  OBJECT_REQUIRED_SCHEMA,
  OBJECT_NULLABLE_SCHEMA,
} from 'schemas/base';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Grid2 as Grid, FormControlLabel, Checkbox } from '@mui/material';
import TextField from 'components/atoms/TextField/TextField';
import ChampionTeamField from 'components/molecules/ChampionTeamField/ChampionTeamField';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const SearchVodView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [champions, setChampions] = useState([]);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Search VOD';

  const fetchData = () => {
    setIsLoading(true);

    fetchChampions().then((res) => {
      setChampions(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    year: '',
    patch: '',
    top: false,
    vod: false,
  };

  const validationSchema = Yup.object().shape({
    year: STRING_SCHEMA,
    patch: STRING_SCHEMA,
    top: BOOLEAN_SCHEMA,
    vod: BOOLEAN_SCHEMA,
    champion_1: OBJECT_REQUIRED_SCHEMA,
    champion_1_role: STRING_REQUIRED_SCHEMA,
    champion_1_team: STRING_REQUIRED_SCHEMA,
  });

  for (let i = 1; i <= 5; i += 1) {
    const draftChampionKey = `champion_${i}`;
    const draftChampionRoleKey = `champion_${i}_role`;
    const draftChampionTeamKey = `champion_${i}_team`;

    initialValues[draftChampionKey] = null;
    initialValues[draftChampionRoleKey] = 'ANY';
    initialValues[draftChampionTeamKey] = 'ANY';

    if (i > 1) {
      validationSchema[draftChampionKey] = OBJECT_NULLABLE_SCHEMA;
      validationSchema[draftChampionRoleKey] = STRING_SCHEMA;
      validationSchema[draftChampionTeamKey] = STRING_SCHEMA;
    }
  }

  const columns = useMemo(() => SEARCH_VOD_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_TOP_TOOLBAR,
  });

  return (
    <>
      <PageTitle title={title} />
      <Box my={2}>
        <Grid container justifyContent="space-between">
          <Grid size={{ xs: 12, md: 9, lg: 5, xl: 4 }}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setData([]);

                searchVods(values).then(({ data: respData }) => {
                  setData(respData);

                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <TextField
                        error={touched.year && !!errors.year}
                        helperText={touched.year && errors.year}
                        name="year"
                        label="Year"
                        value={values.year}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <TextField
                        error={touched.patch && !!errors.patch}
                        helperText={touched.patch && errors.patch}
                        name="patch"
                        label="Patch"
                        value={values.patch}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    {Array.from({ length: 5 }, (_, i) => i + 1).map((i) => {
                      const draftChampionKey = `champion_${i}`;
                      const draftChampionRoleKey = `champion_${i}_role`;
                      const draftChampionTeamKey = `champion_${i}_team`;

                      return (
                        <ChampionTeamField
                          key={i}
                          data={{
                            champions: {
                              field: draftChampionKey,
                              label: `Champion ${i}`,
                              data: champions,
                              error: touched[draftChampionKey] && !!errors[draftChampionKey],
                              helperText: touched[draftChampionKey] && errors[draftChampionKey],
                              value: values[draftChampionKey],
                              onChangeFn: setFieldValue,
                            },
                            role: {
                              field: draftChampionRoleKey,
                              data: ROLES_ANY,
                              error:
                                touched[draftChampionRoleKey] && !!errors[draftChampionRoleKey],
                              helperText:
                                touched[draftChampionRoleKey] && errors[draftChampionRoleKey],
                              value: values[draftChampionRoleKey],
                              onChangeFn: handleChange,
                            },
                            team: {
                              field: draftChampionTeamKey,
                              data: i === 1 ? SEARCH_TEAM_C1_TYPE : SEARCH_TEAM_TYPE,
                              error:
                                touched[draftChampionTeamKey] && !!errors[draftChampionTeamKey],
                              helperText:
                                touched[draftChampionTeamKey] && errors[draftChampionTeamKey],
                              value: values[draftChampionTeamKey],
                              onChangeFn: handleChange,
                            },
                          }}
                        />
                      );
                    })}
                  </Grid>
                  <FormControlLabel
                    control={<Checkbox id="top" name="top" />}
                    label="TOP leagues"
                    checked={values.top}
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    control={<Checkbox id="vod" name="vod" />}
                    label="With VOD"
                    checked={values.vod}
                    onChange={handleChange}
                  />
                  <Box mt={1}>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                      loadingIndicator={LOADING_TEXT}
                    >
                      Search
                    </LoadingButton>
                  </Box>
                </Box>
              )}
            </Formik>
          </Grid>
          <Grid size={{ xs: 12, lg: 7, xxl: 6 }}>
            <MaterialReactTable table={table} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SearchVodView;
