// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import { Grid2 as Grid, Box } from '@mui/material';

// Component
const GameTeamStatistics = ({ blue = {}, red = {} }) => (
  <Grid container alignItems="center">
    {Object.keys(blue).map((item) => (
      <React.Fragment key={item}>
        <Grid size={{ xs: 4 }}>
          <Box display="flex" alignItems="center" justifyContent="center">
            {blue[item]}
          </Box>
        </Grid>
        <Grid size={{ xs: 4 }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            fontSize={16}
          >
            {item}
          </Box>
        </Grid>
        <Grid size={{ xs: 4 }}>
          <Box display="flex" alignItems="center" justifyContent="center">
            {red[item]}
          </Box>
        </Grid>
      </React.Fragment>
    ))}
  </Grid>
);

GameTeamStatistics.propTypes = {
  blue: PropTypes.object,
  red: PropTypes.object,
};

export default GameTeamStatistics;
