// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { TOOLTIP_SLOTS } from 'constants/tooltip';
import { WHITE_MAIN, GREEN_MAIN, RED_MAIN, YELLOW_MAIN, GRAY_MAIN } from 'constants/color';

// Assets
import CircleIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/Info';

// Elements
import { Tooltip } from '@mui/material';

// Component
const MealData = ({ value = null, info = null }) => {
  if (!value) {
    return null;
  }

  let color = WHITE_MAIN;

  if (value === 'Y') {
    color = GREEN_MAIN;
  } else if (value === 'N') {
    color = RED_MAIN;
  } else if (value === 'VL') {
    color = YELLOW_MAIN;
  } else if (value === 'OSE') {
    color = GRAY_MAIN;
  }

  return info ? (
    <Tooltip title={info} slotProps={TOOLTIP_SLOTS}>
      <InfoIcon fontSize="small" sx={{ color }} />
    </Tooltip>
  ) : (
    <CircleIcon fontSize="small" sx={{ color }} />
  );
};

MealData.propTypes = {
  value: PropTypes.string,
  info: PropTypes.string,
};

export default MealData;
