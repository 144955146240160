// React
import React, { useMemo } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Routes
import { gamesBasicURL } from 'routes/app';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Constants
import { HIDE_TOP_TOOLBAR, HIDE_PAGINATION, ROW_NUMBERS } from 'constants/mrt';

// Elements
import RowActions from 'components/atoms/RowActions/RowActions';

// Component
const GamesTable = ({ data, columns, showLink = false }) => {
  const cols = useMemo(() => columns, []);
  const table = useMaterialReactTable({
    columns: cols,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
    ...ROW_NUMBERS,
    enableRowActions: showLink,
    renderRowActions: showLink
      ? ({ row }) => (
          <RowActions
            row={row}
            viewBtn
            viewLink={`${gamesBasicURL}/${row.original.id}`}
            editBtn={false}
            deleteBtn={false}
          />
        )
      : null,
    displayColumnDefOptions: {
      ...ROW_NUMBERS.displayColumnDefOptions,
      'mrt-row-actions': showLink && ROW_NUMBERS,
    },
  });

  return <MaterialReactTable table={table} />;
};

GamesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  showLink: PropTypes.bool,
};

export default GamesTable;
