// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { GREEN_MAIN, RED_MAIN } from 'constants/color';

// Assets
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

// Component
const BooleanData = ({ cell = null, value = null }) => {
  const isTrue = (cell !== null && cell.getValue()) || value;
  const iconColor = isTrue ? GREEN_MAIN : RED_MAIN;

  return isTrue === true ? (
    <CheckIcon fontSize="small" sx={{ color: iconColor }} />
  ) : (
    <CancelIcon fontSize="small" sx={{ color: iconColor }} />
  );
};

BooleanData.propTypes = {
  cell: PropTypes.object,
  value: PropTypes.bool,
};

export default BooleanData;
