// React
import React, { useState, useRef } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Elements
import {
  IconButton,
  Fade,
  Paper,
  ClickAwayListener,
  Popper,
  MenuItem,
  MenuList,
  Divider,
} from '@mui/material';

// Styles
import { StyledNavLink } from './ButtonWithSubmenu.styles';

// Component
const ButtonWithSubmenu = ({ icon, size = 'medium', menu, player = {} }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="Manage"
        size={size}
        sx={{ p: 1 / 3 }}
        onClick={handleToggle}
      >
        {icon}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="left"
        transition
        disablePortal
        style={{ position: 'relative', zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {player ? (
                    <div>
                      <MenuItem sx={{ fontSize: 14 }}>{player.name}</MenuItem>
                      <Divider sx={{ marginTop: '0 !important' }} />
                    </div>
                  ) : null}
                  {menu.map(({ name: itemName, link: itemLink, state: itemState }) => (
                    <StyledNavLink key={itemName} to={itemLink} state={itemState}>
                      <MenuItem sx={{ fontSize: 12 }}>{itemName}</MenuItem>
                    </StyledNavLink>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

ButtonWithSubmenu.propTypes = {
  icon: PropTypes.element.isRequired,
  size: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
  player: PropTypes.object,
};

export default ButtonWithSubmenu;
