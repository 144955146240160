// React
import React from 'react';
import ReactDOM from 'react-dom/client';

// Router
import { BrowserRouter } from 'react-router-dom';

// Helmet
import { HelmetProvider } from 'react-helmet-async';

// Notistack
import { SnackbarProvider } from 'notistack';

// Cookies
import { CookiesProvider } from 'react-cookie';

// Theme
import isPropValid from '@emotion/is-prop-valid';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import { ThemeProvider as ThemeProviderMui } from '@mui/material/styles';
import { theme } from 'theme/theme';

// Constants
import { AUTO_HIDE_DURATION } from 'constants/snackbar';

// Elements
import App from 'views/App';

// Styles
import CssBaseline from '@mui/material/CssBaseline';

// Context
import ContextProvider from './context';

// Styles
import 'assets/css/global.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }

  return true;
};

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <ThemeProviderMui theme={theme}>
              <ContextProvider>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={AUTO_HIDE_DURATION}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <BrowserRouter>
                    <CssBaseline />
                    <App />
                  </BrowserRouter>
                </SnackbarProvider>
              </ContextProvider>
            </ThemeProviderMui>
          </ThemeProvider>
        </CookiesProvider>
      </StyleSheetManager>
    </HelmetProvider>
  </React.StrictMode>,
);
